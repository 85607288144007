import { useEffect, useState } from 'react'
import { i18n, InputNumber } from '../../../Components'
import { Card, CardBody, CardTitle, Col, Table } from 'reactstrap'
import { LoteGetVencendo, LoteUpdateDiasAviso } from '../../../Service/ApiService'
import { CardDias } from './styles'
import { getUser } from '../../../Reducers/storageApp'

export default function Componente() {
  const { contaId } = getUser()
  const [dataGrid, setDataGrid] = useState([])
  const [diasOpen, setDiasOpen] = useState(false)
  const [values, setValues] = useState({ dias: '0' })

  async function loadGrid() {
    const response = await LoteGetVencendo(contaId)
    if (response) {
      setDataGrid(response.list)
      setValues({ dias: response.dias })
    }
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    setTimeout(function () {
      loadGrid()
    }, 500)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contaId])

  async function onAlterDias() {
    if (diasOpen) {
      setDiasOpen(false)
      await LoteUpdateDiasAviso(values.dias, contaId)

      loadGrid()
    } else setDiasOpen(true)
  }

  function onkeyUpDias(ev) {
    if (ev.keyCode === 13) onAlterDias()
  }

  return (
    <Card className="main-card mb-2">
      <CardBody>
        <CardTitle>
          <CardDias>
            {i18n.t('home.lots.title')}
            {diasOpen === false ? (
              <span onClick={onAlterDias} style={{ color: 'red' }}>
                Data de corte próximos {values.dias} dias
              </span>
            ) : (
              <InputNumber autoFocus style={{ width: '60px' }} onChange={onChange} onBlur={onAlterDias} onKeyUp={onkeyUpDias} value={values.dias} name="dias" id="dias" />
            )}
          </CardDias>
        </CardTitle>

        <Col className="tb-report position-relative table-responsive">
          <Table striped>
            <thead>
              <tr>
                <th>{i18n.t('order.itens.lot.columns.nrLot')}</th>
                <th>{i18n.t('order.itens.lot.columns.material')}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.lot.columns.orderLimit')}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.lot.columns.availability')}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t('order.itens.lot.columns.due')}</th>
              </tr>
            </thead>
            <tbody>
              {dataGrid.map((d) => (
                <tr key={d.loteId}>
                  <td>{d.nrLote}</td>
                  <td>
                    {d.materialIdExt} - {d.materialNm}
                  </td>
                  <td style={{ textAlign: 'center' }}>{d.dtLimitePedido}</td>
                  <td style={{ textAlign: 'center' }}>{d.dtDisEstimada}</td>
                  <td style={{ textAlign: 'center' }}>{d.dtVencimento}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </CardBody>
    </Card>
  )
}
