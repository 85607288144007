import React, { useEffect, useState } from 'react'
import { useDispatch, PageTitle, ReactCSSTransitionGroup, i18n, useNavigate, useParams, Loading, Bounce, toast } from '../../../Components'
import { MdContentCopy, MdUndo } from 'react-icons/md'
import { Card, CardBody, Row, Col, Button, CardTitle } from 'reactstrap'
import { OrdemGetDetails } from '../../../Service/ApiService'
import Itens from './Item'
import Remessas from './Remessas'
import Files from './Files'
import Financial from './Financial'
import ModalAltStatus from './ModalAltStatus'
import { getUser } from '../../../Reducers/storageApp'
import { Biz_IsAccessCodigo } from '../../../Service/BizService'

const initValues = { ordemId: 0, ordemIdExt: '', ContaId: '', tpOrdem: '', participante: '', endParticipante: '', nmParticipante: '', itens: [], observacao: '', pedCompra: '', nmTpOrdem: '', nmUsuario: '' }

export default function FormCadastro() {
  const user = getUser()
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [values, setValues] = useState(initValues)
  const [modAltStatus, setModAltStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function loadValues() {
    try {
      const response = await OrdemGetDetails(id)
      var _value = { ...initValues, ...response.ordem }

      if (user.contaId != 1 && _value.contaId != user.contaId) {
        toast('Acesso negado ao pedido', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        window.location.href = '/#/admin/Order/Query'
      }

      if (!_value.ordemIdExt) _value.ordemIdExt = ''
      _value.itens = response.itens
      _value.arquivos = response.arquivos
      _value.titulos = response.titulos

      let remessas = []
      response.remessas.forEach((d, index) => {
        remessas.push({ ...d })
      })

      _value.remessas = remessas

      setValues(_value)
    } catch {}

    setIsLoading(false)
  }

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      loadValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  async function onItem(ev) {
    let _itens = values.itens
    _itens.push({ ...ev })
    setValues({ ...values, itens: _itens })
  }

  async function onCopy() {
    dispatch({ type: 'OrdemId', value: values.ordemId })
    window.location.href = `/#/admin/Order/Add`
  }
  async function onModAltStatus(ev) {
    if (ev) window.location.href = `/#/admin/Order/Query`
    else setModAltStatus(false)
  }

  return (
    <>
      <Loading isVisible={isLoading} />
      <ModalAltStatus showModal={modAltStatus} ordemId={id} onChange={onModAltStatus} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('order.details.heading')} subheading={i18n.t('order.details.subheading')} icon="BsFillCartFill">
            {Biz_IsAccessCodigo('OrdemAltSit') && user?.tpVenda === 'I' && !values.contratoNr && (
              <Button type="button" onClick={() => setModAltStatus(true)} className="btn-pill mr-1" color="danger">
                <MdUndo /> {i18n.t('order.details.form.buttonAltStatus')}
              </Button>
            )}
            {user?.tpVenda === 'I' && !values.contratoNr && (
              <Button type="button" onClick={onCopy} className="btn-pill mr-1" color="info" disabled={!Biz_IsAccessCodigo('OrdemCad')}>
                <MdContentCopy /> {i18n.t('order.edit.form.buttonCopy')}
              </Button>
            )}
            <Button type="button" onClick={() => navigate(-1)} className="btn-pill mr-1" color="secondary">
              {i18n.t('layout.btnGoBack')}
            </Button>
          </PageTitle>
          <div className="page-content">
            <Row>
              <Col md={12}>
                <Card className="main-card mb-1">
                  <CardBody style={{ padding: '0.75rem' }}>
                    <CardTitle style={{ display: 'flex' }}>
                      <span>Dados do pedido</span>
                    </CardTitle>
                    <Row>
                      <Col md={4}>
                        <Col md={12}>
                          {i18n.t('order.details.form.nrOv')}: <strong> {values.ordemIdExt} </strong>
                        </Col>
                        <Col md={12}>
                          {i18n.t('order.edit.form.myOrder')}: <strong>{values.pedCompra}</strong>
                        </Col>
                        <Col md={12}>
                          {i18n.t('order.details.form.buyer')}: <strong>{values.nmUsuario}</strong>
                        </Col>
                        {values.contratoNr && (
                          <Col md={12}>
                            {i18n.t('order.details.form.contract')}: <strong>{values.contratoNr}</strong>
                          </Col>
                        )}
                      </Col>

                      <Col md={8}>
                        <Col md={12}>
                          {i18n.t('order.details.form.tpOrder')}:<strong> {values.nmTpOrdem}</strong>
                        </Col>
                        <Col md={12}>
                          {i18n.t('order.details.form.customer')}: <strong> {values.nmParticipante}</strong>
                        </Col>
                        <Col md={12}>
                          {i18n.t('order.details.form.customerEnd')}:<strong> {values.endParticipante}</strong>
                        </Col>
                        <Col md={12}>
                          {i18n.t('order.details.form.observation')}: <strong> {values.observacao}</strong>
                        </Col>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Itens onChange={onItem} tpOrdemId={values.tpOrdem?.value} itens={values.itens} obrigaLote={values.obrigaLote} />

              <Remessas records={values.remessas} value={{ situacao: values.situacao, dhAtualizacao: values.dhAtualizacao, dhCadastro: values.dhCadastro, dhCadastroExt: values.dhCadastroExt }} />

              <Files files={values.arquivos} />

              <Financial titulos={values.titulos} />
            </Row>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}
