import Lottie from 'react-lottie'
import animationData from './animation.json'
import { Wrapper, BoxImages, SubTitle, Title } from './styles'
import logo from './logocli.png'
const defaultOptions = { loop: true, autoplay: true, animationData: animationData }

export default function NoMatch() {
  return (
    <Wrapper>
      <BoxImages>
        <img src={logo} alt="Logo_App" />

        <Lottie options={defaultOptions} style={{ maxHeight: '400px', maxWidth: '600px' }} />
      </BoxImages>

      <Title>Estamos em Manutenção</Title>

      <SubTitle>Novas melhorias e funcionalidades estão sendo implementadas, retornaremos em breve!</SubTitle>
    </Wrapper>
  )
}
