import React, { useState, useEffect } from 'react'
import { ReactCSSTransitionGroup, ReactDatatable, i18n, PageTitle, Loading } from '../../../../Components'
import { Card, CardBody, Button } from 'reactstrap'
import { ContaGet } from '../../../../Service/ApiService'
import { BsPencil, BsPlus } from 'react-icons/bs'

function FormConsulta() {
  const [DataGrid, setDataGrid] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const columns = [
    { key: 'nome', text: i18n.t('account.query.columns.name'), sortable: true },
    { key: 'situacao', text: i18n.t('account.query.columns.status'), sortable: true, cell: (d) => (d.situacao == 'Ativo' ? <span className="badge badge-success">Ativo</span> : <span className="badge badge-secondary">Inativo</span>) },
    { key: 'qtdeUsuarios', text: i18n.t('account.query.columns.qtdeUsers'), sortable: true },
    { key: 'qtdeParticipantes', text: i18n.t('account.query.columns.qtdeCustomers'), sortable: true },
    {
      key: 'tpVenda',
      text: i18n.t('account.query.columns.typeSale'),
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record.tpVenda === 'D' && 'Direta'}
            {record.tpVenda === 'I' && 'Indireta'}
          </>
        )
      },
    },
    {
      key: 'segmentos',
      text: i18n.t('account.query.columns.segment'),
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record.segmentos.map((d, index) => (
              <span key={index} className="badge badge-alternate" style={{ margin: '0 2px', textTransform: 'capitalize' }}>
                {d.nome}
              </span>
            ))}
          </>
        )
      },
    },
    {
      key: 'action',
      width: 40,
      sortable: false,
      cell: (record) => (
        <button className="btn btn-primary btn-sm" onClick={() => (window.location.href = '/#/Admin/Account/Edit/' + record.contaId)}>
          <BsPencil />
        </button>
      ),
    },
  ]

  const config = {
    key_column: 'contaId',
    page_size: 20,
    length_menu: [10, 20, 50],
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadData() {
    setIsLoading(true)
    try {
      const response = await ContaGet({})
      setDataGrid(response.filter((f) => f.contaId !== 1))
    } catch {}
    setIsLoading(false)
  }
  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('account.query.heading')} subheading={i18n.t('account.query.subheading')}>
            <Button type="button" onClick={() => (window.location.href = `/#/admin/Account/Add`)} className="btn-pill" color="success" style={{ marginRight: '15px' }}>
              <BsPlus size={18} />
            </Button>
          </PageTitle>
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody className="table-responsive">
                <ReactDatatable config={config} records={DataGrid} columns={columns} />
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}

export default FormConsulta
