import React, { Fragment, useState, useEffect } from 'react'
import { Loading, ReactCSSTransitionGroup, Select } from '../../../Components'
import { Row, Col, FormGroup, CustomInput, Label, Input, Button, Card, CardBody, Table } from 'reactstrap'
import { SysLogGet, SysLogGetProcessos } from '../../../Service/ApiService'
import { ServiceIcon } from './styles'
import { BsSearch } from 'react-icons/bs'

export default function FormCadastro() {
  const [logs, setLogs] = useState()
  const [processos, setProcessos] = useState()
  const [values, setValues] = useState({ tipo: 'ERROR', processo: undefined, dtInicio: '', dtTermino: '' })
  const [isLoading, setIsLoading] = useState(false)

  async function loadProcessos() {
    let items = []
    const response = await SysLogGetProcessos({})
    if (response)
      response.forEach((item) => {
        items.push({ value: item, label: item })
      })
    setProcessos(items)
  }

  async function loadGrid(filters = { ...values }) {
    setIsLoading(true)
    try {
      filters.processo = filters.processo?.value ? filters.processo.value : ''
      const response = await SysLogGet(filters)
      if (response) setLogs(response)
    } catch {}
    setIsLoading(false)
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  useEffect(() => {
    async function load() {
      setIsLoading(true)
      var _dtAtual = new Date()
      _dtAtual.setMinutes(_dtAtual.getMinutes() - _dtAtual.getTimezoneOffset())

      var _dtInicio = new Date()
      _dtInicio.setMinutes(_dtInicio.getMinutes() - _dtInicio.getTimezoneOffset())
      _dtInicio.setDate(_dtInicio.getDate() - 3)
      var filters = { processo: {}, dtInicio: _dtInicio.toISOString().substr(0, 10), dtTermino: _dtAtual.toISOString().substr(0, 10) }

      setValues({ ...values, ...filters })

      await loadProcessos()
      await loadGrid({ ...values, ...filters })
    }
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <Card className="main-card mb-2">
          <CardBody style={{ maxWidth: '100%' }}>
            <Row>
              <Col md={2}>
                <Label>Tipo</Label>
                <CustomInput type="select" onChange={onChange} value={values.tipo} id="tipo" name="tipo">
                  <option value=""></option>
                  <option value="Warning">Alerta</option>
                  <option value="ERROR">Erro</option>
                  <option value="Info">Info</option>
                  <option value="SUCCESS">Sucesso</option>
                </CustomInput>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Início</Label>
                  <Input type="date" onChange={onChange} value={values.dtInicio} name="dtInicio" id="dtInicio" />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Término</Label>
                  <Input type="date" onChange={onChange} value={values.dtTermino} name="dtTermino" id="dtTermino" />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label>Processo</Label>
                  <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.processo} isClearable name="processo" className="basic-multi-select" classNamePrefix="select" options={processos} />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label>Conteúdo</Label>
                  <div style={{ display: 'flex' }}>
                    <Input type="text" onChange={onChange} value={values.descricao} name="descricao" id="descricao" />

                    <Button type="button" onClick={() => loadGrid()} className="btn-pill ml-1" color="primary">
                      <BsSearch size={18} />
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              {logs && (
                <Col className="tb-report position-relative table-responsive">
                  <Table striped>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Processo </th>
                        <th>Subprocesso</th>
                        <th>Data</th>
                        <th>Descrição</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs.map((d) => (
                        <tr key={d.sysLogId}>
                          <td>
                            <ServiceIcon Error={d.tipo === 'ERROR'}></ServiceIcon>
                          </td>
                          <td>{d.processo}</td>
                          <td>{d.subProcesso}</td>
                          <td>{d.dhCadastro}</td>
                          <td>{d.descricao}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </>
  )
}
