import React, { Fragment, useEffect, useState } from 'react'

import DashAdmin from './Admin'
import DashCli from './Customer'
import Executivo from './Executivo'
import { getUser } from '../../Reducers/storageApp'

export default function Componente() {
  const { contaId, isExecutivo } = getUser()

  const [userType, setUserType] = useState('')

  useEffect(() => {
    if (contaId === 1) setUserType('Admin')
    else if (isExecutivo) setUserType('Executivo')
    else setUserType('Cli')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contaId])

  return (
    <>
      {
        {
          Executivo: <Executivo />,
          Admin: <DashAdmin />,
          Cli: <DashCli contaId={contaId} />,
          default: <Fragment />,
        }[userType]
      }
    </>
  )
}
