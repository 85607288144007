const messages = {
  pt: {
    translations: {
      layout: {
        header: {
          Language: {
            title: 'Escolha o seu idioma',
            english: 'Inglês',
            portuguese: 'Português (Brasil)',
            Spanish: 'Espanhol',
          },
          Notification: {
            title: 'Notificações',
            body: 'Mensagens não visualizadas',
            noRecords: 'Você não tem nenhuma mensagem no momento',
          },
          profile: 'Meu perfil',
          logOut: 'Sair',
        },
        footer: {
          copyright: `eSysmex`,
          developBy: ' Design and Develop by Bitys',
        },
        menu: {
          home: 'Página Inicial',
          account: 'Conta',
          document: 'Documentos',
          order: 'Pedido',
          faq: 'Perguntas frequentes',
          contact: 'Fale conosco',
          settings: {
            name: 'Configurações',
            profile: 'Perfis',
            user: 'Usuários',
            tpOrder: 'Tp Pedido',
            lotUpload: 'Upload Lotes',
            bomUpload: 'Upload BOM',
            Parameters: 'Parâmetros',
            SysMonitor: 'Monitor do sistema',
            segments: 'Segmentos',
          },
        },
        btnGoBack: 'Voltar',
        buttonSave: 'Salvar',
        daysWeek: {
          0: 'Domingo',
          1: 'Segunda-feira',
          2: 'Terça-feira',
          3: 'Quarta-feira',
          4: 'Quinta-feira',
          5: 'Sexta-feira',
          6: 'Sábado',
        },
      },

      query: {
        loading: 'Aguarde, carregado ...',
        display: 'Exibir _MENU_ registros',
        search: 'Pesquisar ...',
        info: 'Exibindo _START_ até _END_ de _TOTAL_ registros',
        noData: 'Sem registros',
      },

      login: {
        title: 'Bem-vindo ao Portal eSysmex',

        signIn: {
          email: 'E-mail',
          password: 'Senha',
          buttonSubmit: 'Entrar',
          buttonAzure: 'Microsoft',
          SeperatorOr: 'ou',
          buttonRecover: 'Esqueci minha senha',
          MSG_ErrorEmailValidate: 'Informe um e-mail válido',
          MSG_ErrorPassValidate: 'A senha é invalida',
          MSG_ErrorGetTokenApp: 'Erro ao requisitar Token para o aplicativo',
          titleTermToAccept: 'Termo de privacidade! Você deve concordar com o termo abaixo para prosseguir!',
          bodyTermToAccept:
            'Antes de usar o site da Sysmex Corporation (Sysmex), leia as seguintes condições de uso e use o site somente se concordar com estas condições. Ao utilizar este site, você concorda com todas as condições descritas abaixo. Esteja ciente de que as condições de uso podem mudar sem aviso prévio.',
          buttonTermToAccept: 'Concordo',
        },

        recover: {
          email: 'E-mail',
          buttonSubmit: 'Enviar',
          buttonSingIn: 'Já tem uma conta? Entre!',
          MSG_SuccessSend: 'Lhe enviamos um e-mail para recuperação de sua conta!',
          MSG_ValidateEmail: 'Informe um e-mail válido',
          MSG_ErrorGetTokenApp: 'Erro ao requisitar Token para o aplicativo',
        },

        newPass: {
          pass: 'Nova Senha',
          passConfirmation: 'Confirme a Nova Senha',
          validatedPassword: 'Esta senha é fraca',
          validatedPasswordH: 'A senha precisa conter letras maiúsculas e minúsculas, números, caracteres especiais (@, #, $), e ser maior que 6 dígitos',
          buttonSubmit: 'Salvar',
          MSG_Success: 'Senha alterada com sucesso',
          MSG_ErrorPassCompare: 'As senhas não coincidem!',
          MSG_ErrorPassValidate: 'Informe uma senha válida!',
          MSG_ErrorGetTokenApp: 'Erro ao requisitar Token para o aplicativo',
          MSG_ValidateSecurity: 'A senha não é segura, ela precisa conter letras maiúsculas e minúsculas, números, caracteres especiais (@, #, $), e ser maior que 6 dígitos',
        },
      },

      home: {
        heading: 'Página Inicial',
        subheading: 'Acesso rápido a funções disponíveis',
        admin: {
          order: { title: 'Pedidos recentes de clientes' },
          subheading: 'Resumo das operações no portal',
        },
        order: {
          title: 'Pedidos recentes',
          columns: {
            myOrder: 'Ordem de compra',
            orderId: 'N° Sysmex',
            date: 'Data',
            status: 'Situação',
            customer: 'Cliente',
          },
        },
        files: {
          title: 'Bibliotecas de documentos',
        },
        lots: {
          title: 'Lotes com fechamento em breve',
        },
      },

      account: {
        add: {
          heading: 'Contas',
          subheading: 'Cadastro de conta',
          form: {
            name: 'Nome da conta ',
            typeSale: {
              title: 'Tipo de venda',
              direct: 'Direta',
              indirect: 'Indireta',
            },
            status: {
              title: 'Situação',
              ative: 'Ativo',
              underReview: 'Em análise',
              inactive: 'Inativo',
            },
            userName: 'Nome do usuário administrador',
            email: 'E-mail ',
            segment: 'Segmentos',
            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
        },

        edit: {
          heading: 'Contas',
          subheading: 'Editar os dados da conta',
          form: {
            name: 'Nome da conta',
            typeSale: {
              title: 'Tipo de venda',
              direct: 'Direta',
              indirect: 'Indireta',
            },
            status: {
              title: 'Situação',
              ative: 'Ativo',
              underReview: 'Em análise',
              inactive: 'Inativo',
            },
            userName: 'Nome do usuário administrador',
            email: 'E-mail ',
            segment: 'Segmentos',
            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',

          type: {
            account: 'Cadastro',
            user: 'Usuários',
            customer: 'Clientes',
            tpOrder: 'Tp Pedidos',
          },
          customers: {
            columns: {
              custId: 'Cust Id',
              name: 'Nome',
              address: 'Endereço',
              status: 'Ativo?',
              nmAccount: 'Conta',
              blocFinanc: 'Bloqueio',
            },
            modal: {
              title: 'Pesquisar Clientes',
              fieldName: 'Nome do cliente (digite para pesquisar)',
            },
          },
          users: {
            columns: {
              name: 'Nome',
              email: 'E-mail',
              status: 'Situação',
            },
            modal: {
              title: {
                add: 'Adicionar usuário',
                edit: 'Editar usuário',
              },
            },
          },
          tpOrder: {
            columns: {
              nmTpOrdem: 'Tp Pedido',
              qtdeOrdemCIF: 'Qtde pedidos CIF',
            },
            modal: {
              title: 'Adicionar/Editar Tp Pedidos',
              tporder: 'Tp Pedido',
              qtdeOrdemCIF: 'Qtde pedidos CIF',
            },
          },
        },

        query: {
          heading: 'Contas',
          subheading: 'Consulta de contas',
          columns: {
            segment: 'Segmentos',
            name: 'Nome',
            status: 'Situação',
            qtdeUsers: 'Usuários',
            qtdeCustomers: 'Clientes',
            typeSale: 'Tipo de venda',
          },
        },

        profile: {},
      },

      profile: {
        add: {
          heading: 'Perfis de acesso',
          subheading: 'Cadastro de perfis de acessos',
          form: {
            name: 'Nome do perfil',
            isCustomer: 'Cliente',
            isAdmin: 'Admin',
            isAdminLabel: 'Tp Perfil',
            ative: {
              title: 'Situação',
              true: 'Ativo',
              false: 'Inativo',
            },

            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
        },
        edit: {
          heading: 'Perfis de acesso',
          subheading: 'Edição de perfis de acessos',
          form: {
            name: 'Nome do perfil',
            isCustomer: 'Cliente',
            isAdmin: 'Admin',
            isAdminLabel: 'Tp Perfil',
            ative: {
              title: 'Situação',
              true: 'Ativo',
              false: 'Inativo',
            },
            access: {
              title: 'Acesso por funcionalidade',
            },
            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
        },

        query: {
          heading: 'Perfis de acesso',
          subheading: 'Consulta de perfis de acessos',
          columns: {
            name: 'Nome',
            status: 'Situação',
          },
        },
      },

      user: {
        add: {
          heading: 'Usuários',
          subheading: 'Cadastro de usuários',
          form: {
            name: 'Nome completo',
            email: 'E-mail',
            profile: 'Perfil',
            languange: 'Idioma',
            timezone: 'Fuso horário',
            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
        },
        edit: {
          heading: 'Usuários',
          subheading: 'Edição de usuários',
          form: {
            name: 'Nome completo',
            email: 'E-mail',
            status: 'Situação',
            profile: 'Perfil',
            languange: 'Idioma',
            timezone: 'Fuso horário',
            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
        },
        query: {
          heading: 'Usuários',
          subheading: 'Consulta de usuários',
          columns: {
            name: 'Nome',
            email: 'E-mail',
            status: 'Situação',
            dhAceiteTermos: 'Privacidade',
            accounts: 'Contas',
          },
        },
        profile: {
          titleMyData: 'Meus dados',
          account: 'Conta',
          email: 'E-mail',
          language: 'Idioma',
          timezone: 'Fuso Horário',

          titlePassword: 'Alterar minha senha',
          currentPassword: 'Senha atual',
          newPassword: 'Nova senha',
          passwordConfirmation: 'Confirmação de senha',
          btnSave: 'Salvar',

          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
          MSG_PassValidate: 'A confirmação de senha é diferente da senha, digite novamente',
        },
      },

      document: {
        add: {
          heading: 'Biblioteca de documentos',
          subheading: 'Adicionar documentos',
          form: {
            folder: 'Nome da pasta',
            file: 'Arquivo para upload',

            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
          MSG_ErrorValidateFile: 'Selecione o arquivo para upload',
        },
        edit: {
          heading: 'Perfis de acesso',
          subheading: 'Edição de perfis de acessos',
          form: {
            name: 'Nome do perfil',
            ative: {
              title: 'Situação',
              true: 'Ativo',
              false: 'Inativo',
            },
            access: {
              title: 'Acesso por funcionalidade',
            },
            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
        },

        query: {
          heading: 'Biblioteca de documentos',
          subheading: 'Consulta de pastas e documentos',
          columns: {
            name: 'Nome',
            status: 'Situação',
          },
          titleFolders: 'Bibliotecas',
          titleFiles: 'Arquivos da biblioteca ',
        },
      },

      order: {
        add: {
          heading: 'Pedido de venda',
          subheading: 'Inclusão do pedido de venda',
          form: {
            nrOv: 'N° Sysmex',
            myOrder: 'Ordem de compra',
            tpOrder: 'Tipo do pedido',
            customer: 'Empresa/filial',
            customerEnd: 'Endereço de entrega',
            observation: 'Observações (Controle interno do cliente)',
            deliveryDate: 'Prev. Faturamento',
            dtPrevAguardando: 'Aguardando envio',
            buyer: 'Comprador',
            ative: {
              title: 'Situação',
              true: 'Ativo',
              false: 'Inativo',
            },

            buttonSave: 'Salvar como rascunho',
            buttonSend: 'Salvar e enviar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
          MSG_ErrorValidateItens: 'Adicione ao menos um item',
          MSG_ErrorItensLot: 'Selecione o lote nos itens faltantes',
        },
        edit: {
          heading: 'Pedido de venda',
          subheading: 'Edição do pedido de venda',
          form: {
            nrOv: 'N° Sysmex',
            myOrder: 'Ordem de compra',
            tpOrder: 'Tipo do Pedido',
            customer: 'Empresa/Filial',
            customerEnd: 'Endereço de entrega',
            observation: 'Observações (Controle interno do cliente)',
            deliveryDate: 'Prev. Faturamento',
            dtPrevAguardando: 'Aguardando envio',
            addDate: 'Cadastrado em',
            buyer: 'Comprador',
            ative: {
              title: 'Situação',
              true: 'Ativo',
              false: 'Inativo',
            },

            buttonSave: 'Salvar como rascunho',
            buttonSend: 'Salvar e enviar',
            btnCancel: 'Excluir',
            buttonCopy: 'Copiar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
          MSG_ErrorValidateItens: 'Adicione ao menos um item',
        },
        details: {
          heading: 'Pedido de venda',
          subheading: 'Detalhes do Pedido de venda',
          form: {
            buttonAltStatus: 'Voltar para rascunho',
            nrOv: 'N° Sysmex',
            tpOrder: 'Tipo do pedido',
            customer: 'Empresa/Filial',
            customerEnd: 'Endereço de entrega',
            observation: 'Observações (Controle interno do cliente)',
            deliveryDate: 'Prev. Faturamento',
            addDate: 'Cadastrado em',
            buyer: 'Comprador',
            contract: 'Nr Contrato',
            ative: {
              title: 'Situação',
              true: 'Ativo',
              false: 'Inativo',
            },
          },
          modAltStatus: {
            title: 'Voltar o pedido para rascunho',
            msg: 'ATENÇÃO, ao voltar o pedido para rascunho, o cliente poderá realizar alterações e reenviar, porém, serão excluídos os títulos, remessas e histórico desse pedido, você confirma a alteração?',
            btnConfirm: 'Sim, confirmar',
            btnCancel: 'não, cancelar',
            MSG_Error: 'Ops, ocorreu um erro ao alterar o pedido',
          },
        },
        query: {
          heading: 'Pedidos de venda',
          subheading: 'Consulta de pedidos de venda',
          buttonAdd: 'Novo',
          columns: {
            ordemIdExt: 'N° Sysmex',
            myOrder: 'Ordem de compra',
            nmParticipante: 'Empresa/Filial',
            nmTpOrdem: 'Tp pedido/Contrato',
            situacao: 'Situação',
            dhCadastro: 'Data do pedido',
            dtPrevEntrega: 'Prev. Faturamento',
            nmUser: 'Usuário',
          },
          filters: {
            dtStart: 'Início',
            dtEnd: 'Término',
            lot: 'Lote',
            material: 'Material',
            myOrder: 'Ordem de compra',
            status: {
              title: 'Situação',
              Sketch: 'Rascunho',
              WaitingConfirmation: 'Aguardando confirmação',
              AwaitingShipment: 'Aguardando remessa',
              inProgress: 'Remessa em andamento',
              closed: 'Faturado/Concluído',
              canceled: 'Cancelados',
            },
          },
        },

        itens: {
          form: {
            material: 'Material',
            unitValue: 'Valor',
            qtde: 'Qtde',
            amount: 'Total',
            lotNumber: 'Lote',

            buttonSave: 'Salvar',
            buttonAdd: 'Adicionar material',
          },
          grid: {
            materialId: 'Código',
            materialName: 'Descrição',
            unit: 'Unidade',
            qtde: 'Qtde',
            unitValue: 'Valor',
            amount: 'Total',
            lotNumber: 'Lote',
            lotAvailability: 'Prev. Faturamento',
            pending: 'Fat. Pendente',
            type: 'Tipo',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
          MSG_SelectTpOrder: 'Selecione o tipo do pedido',
          MSG_ErrorValidateLot: 'Selecione um lote',
          lot: {
            title: 'Selecione o lote',
            columns: {
              nrLot: 'Lote',
              material: 'Material',
              availability: 'Disp. estimada',
              due: 'Vencimento',
              orderLimit: 'Limite para pedido',
            },
          },
        },
      },

      tpOrder: {
        add: {
          heading: 'Tipos de pedidos',
          subheading: 'Cadastro e configuração de tipo de pedido',
          form: {
            codeExt: 'Código SAP',
            name: 'Nome',
            matGroup: 'Grupos de materiais',
            material: 'Materiais',
            forceLot: 'Apenas materiais com lote',
            isBillOfMaterial: 'Bill Of Material',
            daysForDelivery: 'Dias para entrega',
            daysForDeliveryH: 'Exemplo: 60 dias da data de envio do pedido',
            dayWeekForDelivery: 'Dia da semana para entrega',
            daysAdvance: 'Dias de antecedência',
            daysAdvanceH: 'Quantidade de dias de antecedência que o pedido deve ser enviado para entregar no dia da semana selecionado',
            qtdeOrdemCIF: 'Qtde pedidos CIF',
            segment: 'Segmento',
            ative: {
              title: 'Situação',
              true: 'Ativo',
              false: 'Inativo',
            },

            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
        },
        edit: {
          heading: 'Tipos de pedidos',
          subheading: 'Edição e configuração de tipo de pedido',
          form: {
            codeExt: 'Código SAP',
            name: 'Nome',
            matGroup: 'Grupos de materiais',
            material: 'Materiais',
            forceLot: 'Apenas materiais com lote',
            isBillOfMaterial: 'Bill Of Material',
            daysForDelivery: 'Dias para entrega',
            daysForDeliveryH: 'Exemplo: 60 dias da data de envio do pedido',
            dayWeekForDelivery: 'Dia da semana para entrega',
            daysAdvance: 'Dias de antecedência',
            daysAdvanceH: 'Quantidade de dias de antecedência que o pedido deve ser enviado para entregar no dia da semana selecionado',
            qtdeOrdemCIF: 'Qtde pedidos CIF',
            segment: 'Segmento',
            ative: {
              title: 'Situação',
              true: 'Ativo',
              false: 'Inativo',
            },

            buttonSave: 'Salvar',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
        },

        query: {
          heading: 'Tipos de pedidos',
          subheading: 'Consulta de tipos de pedidos',
          columns: {
            codeExt: 'Código SAP',
            name: 'Nome',
            status: 'Situação',
            groupCount: 'Nr Grupos de Materiais',
            qtdeOrdemCIF: 'Qtde pedidos CIF',
            isBillOfMaterial: 'Bill Of Material',
          },
        },
      },

      financial: {
        grid: {
          remittance: 'Remessa',
          documentDate: 'Dt Documento',
          value: 'Valor',
          dueDate: 'Vencimento',
          openDays: 'Dias em aberto',
          ourNumber: 'Nosso Número',
          documentNumber: 'Docs',
        },
      },

      segment: {
        add: {
          heading: 'Segmentos',
          subheading: 'Cadastro de segmentos de negócios',
        },
        edit: {
          heading: 'Segmentos',
          subheading: 'Alteração de segmentos de negócios',
          form: {},
        },
        form: {
          name: 'Nome',
          segmentIdExt: 'Código Externo',
          ative: {
            title: 'Situação',
            true: 'Ativo',
            false: 'Inativo',
          },
          MSG_ErrorValidate: 'Preencha os campos obrigatórios',
        },

        query: {
          heading: 'Segmentos',
          subheading: 'Consulta de segmentos de negócios',
          columns: {
            name: 'Nome',
            status: 'Situação',
          },
        },
      },

      contract: {
        query: {
          heading: 'Contratos',
          subheading: 'Visualização de contratos',
          columns: {},
        },
      },
    },
  },
}

export { messages }
