import React from 'react'
import { PageTitle, ReactCSSTransitionGroup, i18n } from '../../../Components'

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import CardOrdens from './Orders'
import CardFolders from '../Folders'
import LotsWinning from '../LotsWinning'

export default function DashCli({ contaId }) {
  return (
    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
      <div className="body-tabs body-tabs-layout">
        <PageTitle heading={i18n.t('home.heading')} subheading={i18n.t('home.subheading')} icon="BsGridFill" />
        <div className="page-content">
          {contaId == '' && <li className="list-group-item-danger list-group-item mb-2">Selecione uma conta para habilitar mais funcionalidades</li>}

          {contaId != '' && (
            <Row>
              <Col lg="5">
                <Col lg="12">
                  <Card className="main-card mb-2">
                    <CardBody>
                      <CardTitle>{i18n.t('home.order.title')} </CardTitle>
                      <CardOrdens contaId={contaId} />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="12">
                  <Card className="main-card mb-2">
                    <CardBody>
                      <CardTitle>{i18n.t('home.files.title')}</CardTitle>
                      <CardFolders />
                    </CardBody>
                  </Card>
                </Col>
              </Col>
              <Col lg="7">
                <LotsWinning />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </ReactCSSTransitionGroup>
  )
}
