import React, { useState, useEffect } from 'react'
import { Button, Card, CardBody, Col } from 'reactstrap'
import { UsuarioConUsuarioAddOrUp, UsuarioGet } from '../../../../../Service/ApiService'
import { BsPencil, BsPlus } from 'react-icons/bs'
import ModalUser from './Modal'

import { ReactCSSTransitionGroup, i18n, ReactDatatable, useParams, toast, Bounce, Loading } from '../../../../../Components'
import { MdOutlineLinkOff } from 'react-icons/md'

export default function FormUsuario() {
  const { id } = useParams()
  const [DataGrid, setDataGrid] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [confModal, setConfModal] = useState({ showModal: false, usuarioId: undefined, contaId: id })

  async function onRemoverLink(usuarioId) {
    setIsLoading(true)

    var response = await UsuarioConUsuarioAddOrUp({ usuarioId, contaId: id, operacao: 'D' })
    if (response?.result) loadData()
    else toast(response?.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })

    setIsLoading(false)
  }

  const columns = [
    {
      key: 'nome',
      text: i18n.t('account.edit.users.columns.name'),
      sortable: true,
      cell: (d) =>
        d.isExecutivo ? (
          <>
            {d.nome} <span className="badge badge-focus ml-2">Executivo</span>
          </>
        ) : (
          d.nome
        ),
    },
    { key: 'email', text: i18n.t('account.edit.users.columns.email'), sortable: true },
    { key: 'ativo', text: i18n.t('account.edit.users.columns.status'), sortable: true, cell: (d) => (d.ativo ? <span className="badge badge-success">Ativo</span> : <span className="badge badge-secondary">Inativo</span>) },
    {
      key: 'action',
      width: 80,
      sortable: false,
      cell: (d) => (
        <>
          <button className="btn btn-primary btn-sm" onClick={() => setConfModal({ ...confModal, showModal: true, usuarioId: d.usuarioId })}>
            <BsPencil />
          </button>
          <button className="btn btn-danger btn-sm ml-1" onClick={() => onRemoverLink(d.usuarioId)}>
            <MdOutlineLinkOff size={16} />
          </button>
        </>
      ),
    },
  ]

  const config = {
    key_column: 'usuarioId',
    page_size: 20,
    length_menu: [10, 20, 50],
    show_filter: false,
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadData() {
    const response = await UsuarioGet({ contaId: id })
    setDataGrid(response)
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onModal() {
    setConfModal({ ...confModal, showModal: !confModal.showModal })
    loadData()
  }

  return (
    <>
      <Loading isVisible={isLoading} />
      {confModal.showModal && <ModalUser onChange={onModal} usuarioId={confModal.usuarioId} contaId={confModal.contaId} />}

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <Card className="main-card mb-2">
          <CardBody>
            <Col md={12} className="d-block text-right">
              <Button type="button" onClick={() => setConfModal({ ...confModal, showModal: true, usuarioId: undefined })} className="btn-pill" color="success" style={{ marginRight: '15px', zIndex: '1' }}>
                <BsPlus size={18} />
              </Button>
            </Col>
            <Col md={12} style={{ marginTop: '-30px' }}>
              <ReactDatatable config={config} records={DataGrid} columns={columns} />
            </Col>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </>
  )
}
