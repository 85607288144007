import React from 'react'
import { Route, Routes } from 'react-router-dom'

import ObjAdd from './Add'
import ObjEdit from './Edit'
import ObjQuery from './Query'
import { Biz_IsAccessCodigo } from '../../../Service/BizService'

export default function Settings() {
  return (
    <>
      {!Biz_IsAccessCodigo('Segment') && (window.location.href = '/#/404')}

      <Routes>
        <Route path="/" element={<ObjQuery />} />
        <Route path="/Query" element={<ObjQuery />} />

        <Route path="/Add" element={<ObjAdd />} />
        <Route path="/Edit/:id" element={<ObjEdit />} />
      </Routes>
    </>
  )
}
