import { Card, CardBody } from 'reactstrap'
import FormElement from '../form'

import { PageTitle, ReactCSSTransitionGroup, i18n, useNavigate, useParams } from '../../../../Components'

export default function FormEdit() {
  const { id } = useParams()

  const navigate = useNavigate()

  async function onFormChange() {
    navigate(-1)
  }

  return (
    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
      <div className="body-tabs body-tabs-layout">
        <PageTitle heading={i18n.t('user.edit.heading')} subheading={i18n.t('user.edit.subheading')} />
        <div className="page-content">
          <Card className="main-card mb-2">
            <CardBody>
              <FormElement usuarioId={id} change={onFormChange} />
            </CardBody>
          </Card>
        </div>
      </div>
    </ReactCSSTransitionGroup>
  )
}
