import React, { Fragment } from 'react'
import { Card, CardBody } from 'reactstrap'
import { PageTitle, ReactCSSTransitionGroup, i18n, useParams } from '../../../../Components'
import FormElemtn from '../form'

export default function FormEdit() {
  const { id } = useParams()
  async function onFormChange() {
    window.location.href = '/#/#/Device/Query'
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('segment.edit.heading')} subheading={i18n.t('segment.edit.subheading')} />
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody>
                <FormElemtn change={onFormChange} id={id} />
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
