import api from './api'
const URL_BASE = process.env.REACT_APP_API

export const GetTokenApp = async () => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/TokenApp`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: `"${process.env.REACT_APP_KEYAPI}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const LoginUsuario = async (TokenApp, value) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/Login/`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${TokenApp}` },
      body: `"${value}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const RecuperarSenha = async (TokenApp, email) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/RecuperarLogin`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${TokenApp}` },
      body: JSON.stringify(email),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const IsHashValido = async (TokenApp, hash) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/IsHashValido`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${TokenApp}` },
      body: JSON.stringify(hash),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const AltSenha = async (TokenApp, values) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/AltSenha`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${TokenApp}` },
      body: JSON.stringify(values),
    })
    const json = req.json()
    return json
  } catch (error) {
    return undefined
  }
}

export const AceiteTermos = async (id) => {
  try {
    const req = await api.post(`/Usuario/AceiteTermos?id=${id}`)
    return req.data
  } catch (error) {
    console.error('AceiteTermos', error)
    return undefined
  }
}
//------------------------------------------------
//Conta
export const ContaAdd = async (values) => {
  try {
    const req = await api.post('/Conta/Add', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaGet = async (filter) => {
  try {
    const req = await api.post('/Conta/GetByFilter', filter)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaGetById = async (id) => {
  try {
    const req = await api.get(`/Conta/GetConta?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const ContaUp = async (values) => {
  try {
    const req = await api.post('/Conta/Update', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ConTpOrdemGet = async (contaId) => {
  try {
    const req = await api.get(`/Conta/GetConTpOrdens?contaId=${contaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const ConTpOrdemAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Conta/ConTpOrdemAddOrUpdate', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const ConTpOrdemDel = async (id) => {
  try {
    const req = await api.get(`/Conta/ConTpOrdemDel?ConTpOrdemId=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const ConTpOrdemGetQtdeOrdemCIF = async (tpOrdemId, contaId) => {
  try {
    const req = await api.get(`/Conta/GetQtdeOrdemCIF?tpOrdemId=${tpOrdemId}&contaId=${contaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Perfil
export const PerfilAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Perfil/AddOrUpdate', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const PerfilGet = async () => {
  try {
    const req = await api.get('/Perfil/GetAll')
    return req.data
  } catch (error) {
    return undefined
  }
}

export const PerfilGetById = async (id) => {
  try {
    const req = await api.get(`/Perfil/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const PerfilGetFuncoes = async (perfilId) => {
  try {
    const req = await api.get(`/Perfil/GetFuncoes?perfilId=${perfilId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Usuario
export const UsuarioGet = async (filter) => {
  try {
    const req = await api.post(`/Usuario/GetByFilter`, filter)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Usuario/AddOrUpdate', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetById = async (id) => {
  try {
    const req = await api.get(`/Usuario/GetById?id=${id}`)
    return req.data
  } catch (error) {
    console.error(error)
    return undefined
  }
}
export const UsuarioAlterIdioma = async (value) => {
  try {
    const req = await api.post('/Usuario/AlterIdioma', `"${value}"`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetAcessos = async () => {
  try {
    const req = await api.get('/Usuario/GetAcessos')
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioConUsuarioAddOrUp = async (values) => {
  try {
    const req = await api.post('/Usuario/ConUsuarioAddOrUp', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Participante
export const ParticipantesGet = async (filters) => {
  try {
    const req = await api.post('/Participante/GetParticipantes', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const ParticipanteGetById = async (id) => {
  try {
    const req = await api.get(`/Participante/GetParticipante?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const ParticipanteGetCep = async (cep) => {
  try {
    const req = await api.get(`/Participante/GetCep?EndCEP=${cep}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const RemoverLinkConta = async (id) => {
  try {
    const req = await api.post('/Participante/RemoverLinkConta', id)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const AddLinkConta = async (values) => {
  try {
    const req = await api.post('/Participante/AddLinkConta', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const ParticipanteContato = async (values) => {
  try {
    const req = await api.post('/Participante/Contato', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

//Tipo de ordens
export const TpOrdemAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/TpOrdem/AddOrUpdate', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const TpOrdemGet = async (values = {}) => {
  try {
    const req = await api.post('/TpOrdem/GetAll', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const TpOrdemGetById = async (id) => {
  try {
    const req = await api.get(`/TpOrdem/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const TpOrdemGetQtdeOrdensMes = async (filters) => {
  try {
    const req = await api.post('/TpOrdem/GetQtdeOrdensMes', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

//Retorna a data prevista para entrega

export const TpOrdemGetCalcEntregaByTpOrdem = async (tpOrdemId) => {
  try {
    const req = await api.get(`/TpOrdem/GetCalcEntregaByTpOrdem?tpOrdemId=${tpOrdemId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const TpOrdemAddCalcEnt = async (values) => {
  try {
    const req = await api.post('/TpOrdem/AddCalcEnt', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const TpOrdemRemoveCalcEnt = async (id) => {
  try {
    const req = await api.post(`/TpOrdem/RemoveCalcEnt?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

// Material
export const MatGrupoGet = async () => {
  try {
    const req = await api.get('/Material/GetMatGrupos')
    return req.data
  } catch (error) {
    return undefined
  }
}

export const MateriaisGet = async (filters) => {
  try {
    const req = await api.post('/Material/GetByFilter', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const LoteGet = async (filters) => {
  try {
    const req = await api.post('/Lote/GetByFilter', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const MateriaisGetRecentes = async (filters) => {
  try {
    const req = await api.post('/Material/GetRecentes', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const LoteGetVencendo = async (contaId) => {
  try {
    const req = await api.get(`/Lote/GetLotesVencendo?contaId=${contaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const LoteUpdateDiasAviso = async (dias, contaId) => {
  try {
    const req = await api.post(`/Lote/UpdateDiasAvisoFechaLote?dias=${dias}&contaId=${contaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Ordem
export const OrdemAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Ordem/AddOrUpdate', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}
export const OrdemGetPreco = async (values) => {
  try {
    const req = await api.post('/Ordem/GetPreco', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const OrdensGet = async (filters) => {
  try {
    const req = await api.post('/Ordem/GetByFilter', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const OrdemGetById = async (id) => {
  try {
    const req = await api.get(`/Ordem/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const OrdensGetRecentes = async (contaId) => {
  try {
    const req = await api.get(`/Ordem/GetRecentes?contaId=${contaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const OrdemCancelar = async (id) => {
  try {
    const req = await api.post(`/Ordem/Cancelar?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const OrdemGetDetails = async (id) => {
  try {
    const req = await api.get(`/Ordem/GetDetails?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const OrdemSendSAP = async (id) => {
  try {
    const req = await api.post(`/Ordem/SendOrdemSAP?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const OrdensGetWithError = async () => {
  try {
    const req = await api.get('/Ordem/GetWithError')
    return req.data
  } catch (error) {
    return undefined
  }
}

export const OrdemForRascunho = async (id) => {
  try {
    const req = await api.post(`/Ordem/AltForRascunho?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//SysArquivos
export const SysArquivoAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/SysArquivo/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SysArquivosGet = async (filters) => {
  try {
    const req = await api.post('/SysArquivo/GetByFilter', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SysArquivosGetFolders = async (filters) => {
  try {
    const req = await api.post('/SysArquivo/GetByFilterFolders', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SysArquivoDelete = async (id) => {
  try {
    const req = await api.get(`/SysArquivo/DelSysArquivo?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SysArquivoAddOrUpdateFolder = async (values) => {
  try {
    const req = await api.post('/SysArquivo/AddOrUpdateFolder', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const TitulosGet = async (filters) => {
  try {
    const req = await api.post('/Titulo/GetTitulos', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

//Faq
export const FaqAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Faq/AddOrUpdate', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const FaqGet = async (filters) => {
  try {
    const req = await api.post('/Faq/GetByFilter', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const FaqGetById = async (id) => {
  try {
    const req = await api.get(`/Faq/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//SysParametros
export const SysParametroGet = async () => {
  try {
    const req = await api.get('/SysParametro/GetAll')
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SysParametroAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/SysParametro/AddOrUpdate', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

//SysLog
export const SysLogGetByService = async () => {
  try {
    const req = await api.get('/SysLog/GetByService')
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SysLogGet = async (filters) => {
  try {
    const req = await api.post('/SysLog/GetByFilter', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SysLogGetProcessos = async (filters) => {
  try {
    const req = await api.post('/SysLog/GetProcessos', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysLogGetNotifications = async () => {
  try {
    const req = await api.get('/SysLog/GetForPortal')
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SysLogReadByUsuario = async () => {
  try {
    const req = await api.post('/SysLog/ReadByUsuario')
    return req.data
  } catch (error) {
    return undefined
  }
}

//BOM
export const BomGet = async (filters) => {
  try {
    const req = await api.post('/Bom/GetByFilter', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const BomGetAddons = async (bomId) => {
  try {
    const req = await api.get(`/Bom/GetAddonsByBomId?id=${bomId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const BomGetOptionals = async (bomId) => {
  try {
    const req = await api.get(`/Bom/GetOptionalsByBomId?id=${bomId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Segmentos
export const SegmentoAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Segmento/AddOrUpdate', JSON.stringify(values))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SegmentoGet = async (filters) => {
  try {
    const req = await api.post('/Segmento/GetByFilter', JSON.stringify(filters))
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SegmentoGetById = async (id) => {
  try {
    const req = await api.get(`/Segmento/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Contratos
export const ContratoGet = async (filters) => {
  try {
    const req = await api.post('/Contrato/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContratoGetDetails = async (filters) => {
  try {
    const req = await api.post('/Contrato/GetByFilterDetails', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const ContratoGetById = async (id) => {
  try {
    const req = await api.get(`/Contrato/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContratoAddOrdem = async (values) => {
  try {
    const req = await api.post('/Contrato/AddOrdem', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContratoAddOrdem2 = async (values) => {
  try {
    const req = await api.post('/Contrato/AddOrdem2', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const OrdemGetDashExecutivo = async () => {
  try {
    const req = await api.get(`/Ordem/GetDashExecutivo`)
    return req.data
  } catch (error) {
    return undefined
  }
}
