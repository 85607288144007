import React from 'react'
import { ReactCSSTransitionGroup, i18n, moment } from '../../../../Components'
import { BsEyeFill } from 'react-icons/bs'
import { Badge, Card, CardBody, CardHeader, Col, Table } from 'reactstrap'
import { FormatTextFirstUppercase } from '../../../../helpers/functions'

export default function Componente({ records }) {
  const getBadge = (situacao) => {
    switch (situacao) {
      case '10':
        return (
          <Badge className="btn-block badge" color="warning" style={{ textTransform: 'capitalize' }}>
            Rascunho
          </Badge>
        )
      case '20':
        return (
          <Badge className="btn-block badge" color="focus" style={{ textTransform: 'capitalize' }}>
            Aguardando confirmação
          </Badge>
        )
      case '30':
        return (
          <Badge className="btn-block badge" color="focus" style={{ textTransform: 'capitalize' }}>
            Aguardando remessa
          </Badge>
        )
      case '40':
        return (
          <Badge className="btn-block badge" color="focus" style={{ textTransform: 'capitalize' }}>
            Remessa em andamento
          </Badge>
        )
      case '90':
        return (
          <Badge className="btn-block badge" color="success" style={{ textTransform: 'capitalize' }}>
            Faturado/Concluído
          </Badge>
        )
      case '99':
        return (
          <Badge className="btn-block badge" color="dark" style={{ textTransform: 'capitalize' }}>
            Cancelado
          </Badge>
        )
      default:
        return (
          <Badge className="btn-block badge" color="danger" style={{ textTransform: 'capitalize' }}>
            Indefinido
          </Badge>
        )
    }
  }

  return (
    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
      <Card className="mb-2">
        <CardHeader className="card-header-tab">PEDIDOS RECENTES DE CLIENTES</CardHeader>
        <CardBody className="pt-0">
          <Col className="tb-report position-relative table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <th>{i18n.t('home.order.columns.orderId')} </th>
                  <th>{i18n.t('home.order.columns.date')}</th>
                  <th>{i18n.t('home.order.columns.customer')}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t('home.order.columns.status')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {records.map((d) => (
                  <tr key={d.ordemId}>
                    <td>{d.ordemIdExt}</td>
                    <td>{moment(d.dhCadastro).format('DD/MM/YY')}</td>
                    <td>{FormatTextFirstUppercase(d.nmParticipante)}</td>

                    <td style={{ textAlign: 'center' }}>{getBadge(d.situacao)}</td>
                    <td style={{ textAlign: 'right' }}>
                      <button className="btn btn-outline-primary btn-square btn-sm" onClick={() => (window.location.href = '/#/Admin/Order/Details/' + d.ordemId)}>
                        <BsEyeFill />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </CardBody>
      </Card>
    </ReactCSSTransitionGroup>
  )
}
