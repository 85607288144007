import { useState, useEffect } from 'react'
import { Card, CardBody, Button, Row, Col, FormGroup, Label, Input, CustomInput } from 'reactstrap'
import { ReactCSSTransitionGroup, CardFilter, Loading, Select, PageTitle, moment, ReactDatatable, i18n, MsgSemRegistros, toast, Bounce } from '../../../../Components'
import { ContaGet, UsuarioConUsuarioAddOrUp, UsuarioGet } from '../../../../Service/ApiService'
import { BsPencil, BsPlus, BsXCircle } from 'react-icons/bs'
import { getUser } from '../../../../Reducers/storageApp'
import { FormatTextFirstUppercase } from '../../../../helpers/functions'
import { BoxRemove, BtnRemove, MainRemove } from './styles'

const initValues = { conta: '', ativo: 'true', email: '', nome: '' }

export default function FormConsulta() {
  const [dataGrid, setDataGrid] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState(initValues)
  const [contas, setContas] = useState()

  async function loadContas() {
    var response = await ContaGet({ ativo: true })
    let items = []
    if (response) {
      response.forEach((item) => {
        items.push({ value: item.contaId, label: item.nome })
      })
    }
    setContas(items)
  }

  async function onRemoverLink(usuarioId, contaId) {
    setIsLoading(true)
    var response = await UsuarioConUsuarioAddOrUp({ usuarioId, contaId, operacao: 'D' })
    if (response?.result) loadData()
    else toast(response?.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })

    setIsLoading(false)
  }

  const columns = [
    { key: 'nome', text: i18n.t('user.query.columns.name'), sortable: true },
    { key: 'email', text: i18n.t('user.query.columns.email'), align: 'left', sortable: true },
    {
      key: 'ativo',
      text: i18n.t('user.query.columns.status'),
      sortable: true,
      cell: (d) => (d.ativo ? <span className="badge badge-success">Ativo</span> : <span className="badge badge-secondary">Inativo</span>),
    },
    { key: 'dhAceiteTermos', text: i18n.t('user.query.columns.dhAceiteTermos'), sortable: true, cell: (record) => (record.dhAceiteTermos ? moment(record.dhAceiteTermos).format('DD/MM/YYYY') : '') },
    {
      key: 'contas',
      text: i18n.t('user.query.columns.accounts'),
      sortable: true,
      cell: (d) => (
        <MainRemove>
          {d.contas?.map((e) => (
            <BoxRemove key={e.contaId} className="badge badge-success">
              <span> {FormatTextFirstUppercase(e.nome)}</span>

              {contas?.length > 0 || values.conta?.value == e.contaId ? (
                <BtnRemove className="btn-danger" title="Remover a conta do usuário" onClick={() => onRemoverLink(d.usuarioId, e.contaId)}>
                  <BsXCircle size={14} />
                </BtnRemove>
              ) : (
                <></>
              )}
            </BoxRemove>
          ))}
        </MainRemove>
      ),
    },

    {
      key: 'action',
      width: 40,
      sortable: false,
      cell: (d) => (
        <button className="btn btn-primary btn-sm mr-1" onClick={() => (window.location.href = '/#/Admin/User/Edit/' + d.usuarioId)}>
          <BsPencil />
        </button>
      ),
    },
  ]

  const config = {
    key_column: 'usuarioId',
    page_size: 10,
    length_menu: [10, 20, 50],
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadData(filters = values) {
    setIsLoading(true)
    try {
      var _filters = { ...filters }

      if (_filters.ativo == '') delete _filters.ativo
      else _filters.ativo = _filters.ativo == 'true' ? true : false

      _filters.contaId = filters.conta?.value
      delete _filters.conta

      const response = await UsuarioGet(_filters)
      setDataGrid(response)
    } catch {
      setDataGrid([])
    }
    setIsLoading(false)
  }

  useEffect(() => {
    async function load() {
      const { contaId, contaNm } = await getUser()

      let filters = { ...initValues }
      filters = { ...filters, conta: { value: contaId, label: contaNm } }

      if (contaId == 1) loadContas()

      loadData(filters)
      setValues(filters)
    }
    load()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  return (
    <>
      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('user.query.heading')} subheading={i18n.t('user.query.subheading')}>
            <Button type="button" onClick={() => (window.location.href = `/#/admin/User/Add`)} className="btn-pill mr-1" color="success">
              <BsPlus size={18} />
            </Button>
          </PageTitle>
          <div className="page-content">
            <CardFilter onChange={loadData}>
              {values && (
                <Row>
                  <Col md={4}>
                    <Label>Conta </Label>
                    {!contas ? (
                      <Input type="text" value={values.conta.label} disabled />
                    ) : (
                      <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} isClearable value={values.conta} name="conta" className="basic-multi-select" classNamePrefix="select" options={contas} />
                    )}
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <Label>Nome</Label>
                      <Input type="text" onChange={onChange} value={values.nome} name="nome" />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>E-mail</Label>
                      <Input type="text" onChange={onChange} value={values.email} name="email" />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <Label>Situação</Label>
                    <CustomInput type="select" onChange={onChange} id="ativo" name="ativo" value={values.ativo}>
                      <option value="">Todos</option>
                      <option value="true">Ativos</option>
                      <option value="false">Inativo</option>
                    </CustomInput>
                  </Col>
                </Row>
              )}
            </CardFilter>

            <Card className="main-card mb-2">
              <CardBody className="table-responsive">{dataGrid?.length === 0 ? <MsgSemRegistros /> : <ReactDatatable config={config} records={dataGrid} columns={columns} />}</CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}
