import React from 'react'
import { Route, Routes } from 'react-router-dom'

import ObjAdd from './Add'
import ObjEdit from './Edit'
import ObjQuery from './Query'
import Details from './/Details'

export default function Settings() {
  return (
    <>
      <Routes>
        <Route path="/" element={<ObjQuery />} />
        <Route path="/Query" element={<ObjQuery />} />

        <Route path="/Add" element={<ObjAdd />} />
        <Route path="/Edit/:id" element={<ObjEdit />} />
        <Route path="/Details/:id" element={<Details />} />
      </Routes>
    </>
  )
}
