import { Fragment, React } from 'react'
import { Route, Routes } from 'react-router-dom'

import AppHeader from '../AppHeader'
import AppSidebar from '../AppSidebar'
import AppFooter from '../AppFooter'

import Home from '../../Pages/Home/index'

import Account from '../../Pages/Settings/Account'
import Profile from '../../Pages/Settings/Profile'
import User from '../../Pages/Settings/User'
import Parameters from '../../Pages/Settings/Parameters'
import TpOrder from '../../Pages/Settings/TpOrder'
import Segment from '../../Pages/Settings/Segment'

import DocumentQuery from '../../Pages/Document/Query'
import Order from '../../Pages/Order'
import Financial from '../../Pages/Financial'
import LotUpload from '../../Pages/LotUpload'
import BomUpload from '../../Pages/BomUpload'

import Contact from '../../Pages/Contact'
import Faq from '../../Pages/Faq'
import SysMonitor from '../../Pages/SysMonitor'
import { NotFound } from '../../Components'

import Contract from '../../Pages/Contract'

const Main = () => {
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Routes>
              <Route path="/" exact element={<Home />} />

              <Route path="/Account/*" element={<Account />} />
              <Route path="/Profile/*" element={<Profile />} />
              <Route path="/User/*" element={<User />} />
              <Route path="/TpOrder/*" element={<TpOrder />} />
              <Route path="/Segment/*" element={<Segment />} />
              <Route path="/Contract/*" element={<Contract />} />

              <Route path="/SysMonitor/*" element={<SysMonitor />} />
              <Route path="/Financial/*" element={<Financial />} />
              <Route path="/LotUpload/*" element={<LotUpload />} />
              <Route path="/BomUpload/*" element={<BomUpload />} />
              <Route path="/Document/*" element={<DocumentQuery />} />

              <Route path="/Order/*" element={<Order />} />

              <Route path="/Contact/*" element={<Contact />} />
              <Route path="/Faq" element={<Faq />} />

              <Route path="/Parameters/*" element={<Parameters />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  )
}
export default Main
