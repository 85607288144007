import React, { useState, useEffect } from 'react'
import { ReactCSSTransitionGroup, i18n, PageTitle, Loading, MsgSemRegistros, CardFilter, Select } from '../../../Components'
import { Button, Card, CardBody, Col, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap'
import { ContratoGet, ParticipantesGet } from '../../../Service/ApiService'
import { getUser } from '../../../Reducers/storageApp'
import { BsPlus } from 'react-icons/bs'
import Item from './Item'

const initValues = { contaId: 0, numeroOV: '', isEncerrado: 'false', participante: '' }

export default function ContractQuery() {
  const [dataGrid, setDataGrid] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState(initValues)
  const [participantes, setParticipantes] = useState()

  async function loadParticipantes(contaId) {
    var response = await ParticipantesGet({ ativo: true, contaId })
    let items = []
    if (response) {
      response.forEach((item) => {
        items.push({ value: item.participanteId, label: item.nomeFantasia })
      })
    }
    setParticipantes(items)
  }

  async function loadData(filters = values) {
    setIsLoading(true)
    try {
      var _filters = { ...filters }

      if (_filters.isEncerrado == '') delete _filters.isEncerrado
      else _filters.isEncerrado = _filters.isEncerrado == 'true' ? true : false

      _filters.participanteId = values.participante?.value
      delete _filters.participante

      const response = await ContratoGet(_filters)
      setDataGrid(response)
    } catch {
      setDataGrid([])
    }
    setIsLoading(false)
  }

  useEffect(() => {
    async function load() {
      const { contaId } = await getUser()
      let filters = { ...initValues, contaId }
      loadData(filters)
      setValues(filters)

      loadParticipantes(contaId)
    }
    load()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  return (
    <>
      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('contract.query.heading')} subheading={i18n.t('contract.query.subheading')} icon="BsFillBriefcaseFill">
            {dataGrid?.length > 0 && (
              <Button type="button" onClick={() => (window.location.href = `/#/admin/Contract/OrderAdd`)} className="btn-pill" color="success" disabled={dataGrid?.length === 0 ? true : false}>
                <BsPlus size={18} />
                <span className="mr-1"> Add Pedidos</span>
              </Button>
            )}
          </PageTitle>

          <div className="page-content">
            <CardFilter onChange={loadData}>
              {values && (
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Número</Label>
                      <Input type="text" onChange={onChange} value={values.numeroOV} name="numeroOV" />
                    </FormGroup>
                  </Col>

                  <Col md={7}>
                    <Label>{i18n.t('order.add.form.customer')} </Label>
                    <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} isClearable value={values.participante} name="participante" className="basic-multi-select" classNamePrefix="select" options={participantes} />
                  </Col>

                  <Col md={3}>
                    <Label>Situação</Label>
                    <CustomInput type="select" onChange={onChange} id="isEncerrado" name="isEncerrado" value={values.isEncerrado}>
                      <option value="">Todos</option>
                      <option value="false">Contratos disponíveis</option>
                      <option value="true">Contratos encerrados</option>
                    </CustomInput>
                  </Col>
                </Row>
              )}
            </CardFilter>

            {dataGrid?.length === 0 ? (
              <Card className="main-card">
                <CardBody>
                  <MsgSemRegistros message={'Nenhum contrato encontrado'} />
                </CardBody>
              </Card>
            ) : (
              dataGrid?.map((d) => <Item key={d.contratoId} value={d} />)
            )}
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}
