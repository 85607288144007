import styled from 'styled-components'

export const MainRemove = styled.div`
  display: ruby;
`
export const BoxRemove = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px 3px 0;
  padding: 0 !important;
  text-transform: capitalize;
  > span {
    padding: 5px;
  }
`
export const BtnRemove = styled.div`
  background: #d92550;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 5px 4px;

  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;
`
