import React, { useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Input, Button, Alert } from 'reactstrap'
import { ConTpOrdemGetQtdeOrdemCIF, OrdemAddOrUpdate, OrdemCancelar, OrdemGetById, OrdemGetPreco, TpOrdemGetQtdeOrdensMes } from '../../../Service/ApiService'
import { useDispatch, PageTitle, ReactCSSTransitionGroup, i18n, useNavigate, toast, Bounce, Loading, useParams } from '../../../Components'

import { MdContentCopy } from 'react-icons/md'
import Itens from '../Item'
import ModalMessageCIF from '../ModalMessageCIF'
import { getUser } from '../../../Reducers/storageApp'
import ModalPreco from '../ModalPreco'
import { filterSpecialChars } from '../../../helpers/functions'
import { Biz_IsAccessCodigo } from '../../../Service/BizService'

const initValues = { ordemId: 0, ordemIdExt: '', contaId: '', tpOrdem: '', participante: '', parEndereco: '', itens: [], observacao: '', pedCompra: '' }

export default function FormCadastro() {
  const { contaId } = getUser()
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState(initValues)
  const [confCIF, setConfCIF] = useState({ showModal: false })
  const [modalPreco, setModalPreco] = useState({ show: false })

  const pageEndRef = React.createRef()
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

  async function loadValues() {
    const response = await OrdemGetById(id)
    if (response) {
      //if (response.ordem.situacao > 10) window.location.href = '/admin/Order/Details/' +  id

      var _value = { ...initValues, ...response.ordem }

      if (!_value.ordemIdExt) _value.ordemIdExt = ''

      var _dhCadastro = new Date(_value.dhCadastro)
      _dhCadastro.setMinutes(_dhCadastro.getMinutes() - _dhCadastro.getTimezoneOffset())
      _value.dhCadastro = _dhCadastro.toISOString().substr(0, 16)

      _value.itens = response.itens

      setValues(_value)
    }
  }

  function onChange(ev) {
    var { name, value } = ev.target
    if (['pedCompra', 'observacao'].includes(name)) value = filterSpecialChars(value)
    setValues({ ...values, [name]: value })
  }

  function onSendOrder() {
    let isHasPreco = true
    values.itens.forEach((item) => {
      if (!item.preco > 0) {
        isHasPreco = false
      }
    })

    if (isHasPreco === false) {
      setModalPreco({ show: true })
    } else onSubmit('20')
  }

  async function onSubmit(situacao) {
    if (values.itens.length === 0) {
      toast(i18n.t('order.edit.MSG_ErrorValidateItens'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    if (situacao === '20') {
      var contaQtdeOrdemCIF = await ConTpOrdemGetQtdeOrdemCIF(values.tpOrdem.value, contaId)
      if ((contaQtdeOrdemCIF === undefined ? values.tpOrdem?.qtdeOrdemCIF : contaQtdeOrdemCIF) > 0) {
        var qtdeOrdensMes = await TpOrdemGetQtdeOrdensMes({ contaId, tpOrdemId: values.tpOrdem.value })

        if (qtdeOrdensMes >= (contaQtdeOrdemCIF === undefined ? values.tpOrdem?.qtdeOrdemCIF : contaQtdeOrdemCIF)) {
          setConfCIF({ ...confCIF, showModal: true })
          return
        }
      }
    }
    onSalvar(situacao)
  }

  function MontaPedido(situacao) {
    var _values = { ...values }
    var _itens = []

    _values.situacao = situacao

    values.itens.forEach((item) => {
      _itens.push({ ordItemId: item.ordItemId, materialId: item.materialId, preco: item.preco, qtde: item.qtde, loteId: item.loteId })
    })
    _values.itens = _itens

    return _values
  }

  async function onSalvar(situacao) {
    setIsLoading(true)

    const response = await OrdemAddOrUpdate(MontaPedido(situacao))
    if (response.result) {
      window.location.href = '/#/admin/Order/Query'
    } else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onItem(ev) {
    setValues({ ...values, itens: ev })
    scrollToRef(pageEndRef)
  }

  async function onCancelar() {
    await OrdemCancelar(id)

    window.location.href = '/#/admin/Order/Query'
  }
  async function onCopy(ordemId) {
    dispatch({ type: 'OrdemId', value: ordemId })
    window.location.href = `/#/admin/Order/Add`
  }

  function onModalCIF(ev) {
    setConfCIF({ ...confCIF, showModal: !confCIF.showModal })
    if (ev === true) onSalvar('20')
  }

  async function onGetPreco() {
    setIsLoading(true)
    try {
      var _values = MontaPedido('05')
      const response = await OrdemGetPreco(_values)

      if (response?.result) {
        let _itens = []
        for (let index = 0; index < values.itens.length; index++) {
          _itens.push({ ...values.itens[index], preco: response?.itens[index].preco })
        }

        setValues({ ...values, itens: _itens })
      } else toast('Ops, ocorreu um problema ao realizar a cotação', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    } catch {}
    setIsLoading(false)
  }
  async function onModalPreco(ev) {
    setModalPreco({ show: false })
    if (ev === true) {
      await onGetPreco()
    } else if (ev === false) onSubmit('20')
  }

  return (
    <>
      {!Biz_IsAccessCodigo('OrdemEdit') && (window.location.href = '/#/404')}

      <Loading isVisible={isLoading} />
      <ModalMessageCIF onChange={onModalCIF} showModal={confCIF.showModal} />
      {modalPreco.show && <ModalPreco onChange={onModalPreco} />}

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('order.edit.heading')} subheading={i18n.t('order.edit.subheading')} icon="BsFillCartFill" />
          <div className="page-content">
            {values.participante?.bloqCredito === 'Sim' && (
              <Alert color="danger">
                Seu cadastro possuí pendências ou inconsistências. Por gentileza entre em{' '}
                <a href="/Admin/Contact" className="alert-link">
                  contato com a Sysmex
                </a>{' '}
                para regularização. Não será possível o envio de novas ordens.
              </Alert>
            )}
            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <Label>{i18n.t('order.edit.form.nrOv')} </Label>
                      <Input type="text" name="ordemIdExt" id="ordemIdExt" disabled value={values.ordemIdExt} />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label>{i18n.t('order.edit.form.myOrder')} </Label>
                      <Input type="text" name="pedCompra" id="pedCompra" value={values.pedCompra} onChange={onChange} maxLength={16} />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <Label>{i18n.t('order.edit.form.tpOrder')} *</Label>
                    <Input type="text" name="tpOrdem" id="tpOrdem" disabled value={values.tpOrdemNm} />
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>{i18n.t('order.edit.form.buyer')} </Label>
                      <Input type="text" value={values.usuarioNm} name="nmUsuario" id="nmUsuario" disabled />
                    </FormGroup>
                  </Col>

                  <Col md={5}>
                    <Label>{i18n.t('order.edit.form.customer')} * </Label>
                    <Input type="text" name="participante" id="participante" disabled value={values.participanteNm} />
                  </Col>

                  <Col md={7}>
                    <Label> {i18n.t('order.edit.form.customerEnd')} </Label>
                    <Input type="text" value={values.parEndereco} disabled />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Itens onChange={onItem} tpOrdemId={values.tpOrdemId} itens={values.itens} obrigaLote={values.obrigaLote} contaId={values.contaId} />
            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>{i18n.t('order.edit.form.observation')} </Label>
                      <Input type="textarea" rows="2" value={values.observacao} onChange={onChange} name="observacao" id="observacao" />
                    </FormGroup>
                  </Col>

                  <Col md={6} className="btn_itens text-right" style={{ margin: 'auto' }}>
                    <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                      {i18n.t('layout.btnGoBack')}
                    </Button>
                    <Button type="button" onClick={onCopy} className="btn-wide mr-2 btn-lg" color="info" style={{ marginRight: '5px' }} disabled={!values.ordemId}>
                      <MdContentCopy /> {i18n.t('order.edit.form.buttonCopy')}
                    </Button>

                    <Button type="button" onClick={onCancelar} className="btn-wide mr-2 btn-lg" color="danger">
                      {i18n.t('order.edit.form.btnCancel')}
                    </Button>

                    {values.participante?.bloqCredito !== 'Sim' && (
                      <>
                        <Button type="button" className="btn-wide mr-2 btn-lg" color="focus" onClick={onGetPreco} disabled={values.itens?.length > 0 ? false : true}>
                          Calcular preço
                        </Button>

                        <Button type="button" onClick={onSendOrder} className="btn-wide mr-2 btn-lg" color="success">
                          {i18n.t('order.edit.form.buttonSend')}
                        </Button>
                      </>
                    )}

                    <Button
                      type="button"
                      onClick={() => {
                        onSubmit('10')
                      }}
                      className="btn-wide mr-2 btn-outline-primary btn-square btn-lg"
                    >
                      {i18n.t('order.edit.form.buttonSave')}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
      <div ref={pageEndRef} />
    </>
  )
}
