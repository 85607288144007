import React, { Fragment } from 'react'

import Process from './Process'
import Logs from './Logs'
import { PageTitle, Tabs } from '../../Components'
import { Biz_IsAccessCodigo } from '../../Service/BizService'
//import Tabs from 'react-responsive-tabs'

function FormEdit(props) {
  const tabsContent = [
    {
      title: 'Processos',
      content: <Process {...props} />,
    },
    {
      title: 'Logs',
      content: <Logs {...props} />,
    },
  ]

  function getTabs() {
    return tabsContent.map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content,
      key: index,
    }))
  }

  return (
    <>
      {!Biz_IsAccessCodigo('SysMonitor') && (window.location.href = '/#/404')}

      <PageTitle heading="Monitor do sistema" subheading="Integrações, situações de serviços e logs" />
      <div className="page-content">
        <Tabs tabsWrapperClass="body-tabs body-tabs-layout" transform={false} showInkBar={true} items={getTabs(props)} />
      </div>
    </>
  )
}

export default FormEdit
