import React from 'react'
import Conta from './Conta'
import Usuario from './User'
import Cliente from './Custormer'
import TpOrder from './TpOrder'

import { PageTitle, i18n, Tabs } from '../../../../Components'

export default function FormEdit(props) {
  const tabsContent = [
    {
      title: i18n.t('account.edit.type.account'),
      content: <Conta {...props} />,
    },
    {
      title: i18n.t('account.edit.type.user'),
      content: <Usuario {...props} />,
    },
    {
      title: i18n.t('account.edit.type.customer'),
      content: <Cliente {...props} />,
    },
    {
      title: i18n.t('account.edit.type.tpOrder'),
      content: <TpOrder {...props} />,
    },
  ]

  function getTabs() {
    return tabsContent.map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content,
      key: index,
    }))
  }

  return (
    <>
      <PageTitle heading={i18n.t('account.edit.heading')} subheading={i18n.t('account.edit.heading')} />
      <div className="page-content">
        <Tabs tabsWrapperClass="body-tabs body-tabs-layout" transform={false} showInkBar={true} items={getTabs(props)} />
      </div>
    </>
  )
}
