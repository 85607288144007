import { Card, CardBody } from 'reactstrap'
import FormUser from '../form'
import { PageTitle, ReactCSSTransitionGroup, i18n } from '../../../../Components'
import { getUser } from '../../../../Reducers/storageApp'

export default function FormCadastro() {
  const user = getUser()

  async function onFormChange() {
    window.location.href = '/#/admin/User/Query'
  }

  return (
    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
      <div className="body-tabs body-tabs-layout">
        <PageTitle heading={i18n.t('user.add.heading')} subheading={i18n.t('user.add.subheading')} />
        <div className="page-content">
          <Card className="main-card mb-2">
            <CardBody>
              <FormUser change={onFormChange} contaId={user.contaId} />
            </CardBody>
          </Card>
        </div>
      </div>
    </ReactCSSTransitionGroup>
  )
}
