import React, { Fragment, useState, useEffect } from 'react'
import { Button, Card, CardBody, Col } from 'reactstrap'

import { ConTpOrdemDel, ConTpOrdemGet } from '../../../../../Service/ApiService'
import { BsPencil, BsPlus, BsFillTrashFill } from 'react-icons/bs'
import ModalUser from './Modal'

import { ReactCSSTransitionGroup, i18n, ReactDatatable, useParams } from '../../../../../Components'

export default function FormUsuario() {
  const { id } = useParams()
  const [DataGrid, setDataGrid] = useState()
  const [confModal, setConfModal] = useState({ showModal: false, conTpOrdem: undefined, contaId: id })

  const columns = [
    { key: 'nmTpOrdem', text: i18n.t('account.edit.tpOrder.columns.nmTpOrdem'), sortable: true },
    { key: 'qtdeOrdemCIF', text: i18n.t('account.edit.tpOrder.columns.qtdeOrdemCIF'), align: 'left', sortable: true },
    {
      key: 'action',
      text: '#',
      className: 'action',
      width: 80,
      align: 'left',
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-danger btn-sm"
              style={{ marginRight: '5px' }}
              onClick={() => {
                onRemove(record.conTpOrdemId)
              }}
            >
              <BsFillTrashFill size={14} />
            </button>

            <button className="btn btn-primary btn-sm" onClick={() => setConfModal({ ...confModal, showModal: true, conTpOrdem: record })} style={{ marginRight: '5px' }}>
              <BsPencil />
            </button>
          </Fragment>
        )
      },
    },
  ]
  const config = {
    key_column: 'conTpOrdemId',
    page_size: 20,
    length_menu: [10, 20, 50],
    show_filter: false,
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function onRemove(conTpOrdemId) {
    await ConTpOrdemDel(conTpOrdemId)
    loadGrid()
  }

  async function loadGrid() {
    const response = await ConTpOrdemGet(id)
    setDataGrid(response)
  }

  useEffect(() => {
    loadGrid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onModal() {
    setConfModal({ ...confModal, showModal: !confModal.showModal })
    loadGrid()
  }

  return (
    <Fragment>
      <ModalUser onChange={onModal} showModal={confModal.showModal} listTpOrdem={DataGrid} conTpOrdem={confModal.conTpOrdem} contaId={confModal.contaId} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <Card className="main-card mb-2">
          <CardBody>
            <Col md={12} className="d-block text-right">
              <Button type="button" onClick={() => setConfModal({ ...confModal, showModal: true, conTpOrdem: undefined })} className="btn-pill" color="success" style={{ marginRight: '15px', zIndex: '1' }}>
                <BsPlus size={18} />
              </Button>
            </Col>
            <Col md={12} style={{ marginTop: '-30px' }}>
              <ReactDatatable config={config} records={DataGrid} columns={columns} />
            </Col>
          </CardBody>
        </Card>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}
