import React, { useState, useEffect } from 'react'
import { Row } from 'reactstrap'
import { InputCheck } from '../../../Components'

function InputComponent({ value, onChange, ...inputProps }) {
  const [state, setstate] = useState(value)

  useEffect(() => {
    setstate(value)
  }, [value])

  const onInput = (ev) => {
    setstate(ev.target.value)
    if (onChange) {
      onChange({
        target: { value: ev.target.value, ...inputProps },
      })
    }
  }

  return (
    <Row style={{ marginLeft: '0' }}>
      <InputCheck value={state} onChange={onInput} {...inputProps} />
    </Row>
  )
}

export default InputComponent
