const messages = {
  en: {
    translations: {
      layout: {
        header: {
          Language: {
            title: 'Choose your language',
            english: 'English',
            portuguese: 'Portuguese (Brazil)',
            Spanish: 'Spanish',
          },
          Notification: {
            title: 'Notifications',
            body: 'Unviewed messages ',
            noRecords: 'Unviewed messages',
          },
          profile: 'My profile',
          logOut: 'Logout',
        },
        footer: {
          copyright: `rights reserved ${new Date().getFullYear()} Sysmex`,
          developBy: 'Design and Develop by Bitys',
        },
        menu: {
          home: 'Home Page',
          account: 'Account',
          document: 'Documents',
          order: 'Order',
          settings: {
            name: 'Settings',
            profile: 'Profiles',
            user: 'Users',
            tpOrder: 'Order type',
            lotUpload: 'Lot Upload',
            Parameters: 'Parameters',
            bomUpload: 'BOM Upload',
          },
        },
        btnGoBack: 'Go back',
        buttonSave: 'Save',
        daysWeek: {
          0: 'Sunday',
          1: 'Monday',
          2: 'Tuesday',
          3: 'Wednesday',
          4: 'Thursday',
          5: 'Friday',
          6: 'Saturday',
        },
      },

      query: {
        loading: 'Wait, loaded ...',
        display: 'Show _MENU_ records',
        search: 'To look for ...',
        info: 'Displaying _START_ through _END_ of _TOTAL_ records',
        noData: 'No records',
      },

      login: {
        title: 'Welcome to the eSysmex Portal',

        signIn: {
          email: 'E-mail',
          password: 'Password',
          buttonSubmit: 'Log in',
          buttonAzure: 'Microsoft',
          SeperatorOr: 'or',
          buttonRecover: 'I forgot my password',
          MSG_ErrorEmailValidate: 'Please provide a valid e-mail',
          MSG_ErrorPassValidate: 'The password is invalid',
          MSG_ErrorGetTokenApp: 'Error requesting Token for the application',
          titleTermToAccept: 'Privacy Statement! You must agree to the term below to accept!',
          bodyTermToAccept:
            'Before using the website of Sysmex Corporation (Sysmex), please read the following conditions of use and use the site only if you agree to these conditions. By using this site, you agree to all the conditions described below. Please be aware that the conditions of use may change without prior notice.',
          buttonTermToAccept: 'I agree',
        },

        recover: {
          email: 'E-mail',
          buttonSubmit: 'To send',
          buttonSingIn: 'Already have an account? In between!',
          MSG_SuccessSend: 'We have sent you an e-mail to recover your account!',
          MSG_ValidateEmail: 'Please provide a valid e-mail',
          MSG_ErrorGetTokenApp: 'Error requesting Token for the application',
        },

        newPass: {
          pass: 'New password',
          passConfirmation: 'Confirm New Password',
          validatedPassword: 'This password is weak',
          validatedPasswordH: 'The password must contain uppercase and lowercase letters, numbers, special characters (@, #, $), and be longer than 6 digits',

          buttonSubmit: 'To save',
          MSG_Success: 'Password changed successfully',
          MSG_ErrorPassCompare: 'Passwords don`t match!',
          MSG_ErrorPassValidate: 'Please enter a valid password!',
          MSG_ErrorGetTokenApp: 'Error requesting Token for the application',
          MSG_ValidateSecurity: 'The password is not secure, it must contain uppercase and lowercase letters, numbers, special characters (@, #, $), and be longer than 6 digits',
        },
      },

      home: {
        heading: 'Home page',
        subheading: 'Quick access to available functions',
        admin: {
          subheading: 'Summary of operations on the portal',
          order: { title: 'Recent customer orders' },
        },
        order: {
          title: 'Recent orders',
          columns: {
            myOrder: 'My request',
            orderId: 'N° Sysmex',
            date: 'Date',
            status: 'Status',
            customer: 'Customer',
          },
        },
        files: {
          title: 'Document libraries',
        },
        lots: {
          title: 'Lots closing soon',
        },
      },

      account: {
        add: {
          heading: 'Accounts',
          subheading: 'Account registration',
          form: {
            name: 'Account name ',
            status: {
              title: 'Status',
              ative: 'Active',
              underReview: 'Under analysis',
              inactive: 'Inactive',
            },
            userName: 'Username',
            email: 'E-mail ',
            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
        },

        edit: {
          heading: 'Accounts',
          subheading: 'Edit account details',
          form: {
            name: 'Account name',
            status: {
              title: 'Status',
              ative: 'Active',
              underReview: 'Under analysis',
              inactive: 'Inactive',
            },
            userName: 'Username',
            email: 'E-mail ',
            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',

          type: {
            account: 'Register',
            user: 'Users',
            customer: 'Customers',
          },
          customers: {
            columns: {
              custId: 'Cust Id',
              name: 'Name',
              address: 'Address',
              status: 'Active?',
              nmAccount: 'Account',
              blocFinanc: 'Block',
            },
            modal: {
              title: 'Search Customers',
              fieldName: 'Customer name (type to search)',
            },
          },
          users: {
            columns: {
              name: 'Name',
              email: 'E-mail',
              status: 'Active?',
            },
            modal: {
              title: {
                add: 'Add user',
                edit: 'Edit user',
              },
            },
          },
        },

        query: {
          heading: 'Accounts',
          subheading: 'Account inquiry',
          columns: {
            name: 'Name',
            status: 'Status',
            qtdeUsers: 'Users',
            qtdeCustomers: 'Customers',
          },
        },

        profile: {},
      },

      profile: {
        add: {
          heading: 'Access profiles',
          subheading: 'Access profile registration',
          form: {
            name: 'Profile name',
            isCustomer: 'Customer',
            isAdmin: 'Admin',
            isAdminLabel: 'Profile type',
            ative: {
              title: 'Status',
              true: 'Active',
              false: 'Inactive',
            },

            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
        },
        edit: {
          heading: 'Access profiles',
          subheading: 'Editing access profiles',
          form: {
            name: 'Profile name',
            ative: {
              title: 'Status',
              true: 'Active',
              false: 'Inactive',
            },
            access: {
              title: 'Access by functionality',
            },
            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
        },

        query: {
          heading: 'Access profiles',
          subheading: 'Access profiles consultation',
          columns: {
            name: 'Name',
            status: 'Status',
          },
        },
      },

      user: {
        add: {
          heading: 'Users',
          subheading: 'User registration',
          form: {
            name: 'Full name',
            email: 'E-mail',
            profile: 'Profile',
            languange: 'Language',
            timezone: 'Timezon',
            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
        },
        edit: {
          heading: 'Users',
          subheading: 'User editing',
          form: {
            name: 'Full name',
            email: 'E-mail',
            status: 'Status',
            profile: 'Profile',
            languange: 'Language',
            timezone: 'Timezone',
            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
        },
        query: {
          heading: 'Users',
          subheading: 'User consultation',
          columns: {
            name: 'Name',
            email: 'E-mail',
            status: 'Status',
            dhAceiteTermos: 'Privacy',
            accounts: 'Accounts',
          },
        },
        profile: {
          titleMyData: 'My data',
          account: 'Account',
          email: 'E-mail',
          language: 'Language',
          timezone: 'Time zone',

          titlePassword: 'Change my password',
          currentPassword: 'Current password',
          newPassword: 'New password',
          passwordConfirmation: 'Password confirmation',
          btnSave: 'Save',

          MSG_ErrorValidate: 'Fill in the mandatory fields',
          MSG_PassValidate: 'Password confirmation is different from password, re-enter',
        },
      },

      document: {
        add: {
          heading: 'Document library',
          subheading: 'Add documents',
          form: {
            folder: 'Folder name',
            file: 'File to upload',

            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
          MSG_ErrorValidateFile: 'Select the file to upload',
        },
        edit: {
          heading: 'Access profiles',
          subheading: 'Editing access profiles',
          form: {
            name: 'Profile name',
            ative: {
              title: 'Status',
              true: 'Active',
              false: 'Inactive',
            },
            access: {
              title: 'Access by functionality',
            },
            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
        },

        query: {
          heading: 'Document library',
          subheading: 'Query folders and documents',
          columns: {
            name: 'Name',
            status: 'Status',
          },
        },
      },

      order: {
        add: {
          heading: 'Sales order',
          subheading: 'Inclusion of sales order',
          form: {
            nrOv: 'N° Sysmex',
            myOrder: 'My request',
            tpOrder: 'Order Type',
            customer: 'Company/Branch',
            customerEnd: 'Delivery address',
            observation: 'Comments',
            deliveryDate: 'Estimated Billing Date',
            buyer: 'Buyer',
            dtPrevAguardando: 'Waiting to be sent',
            ative: {
              title: 'Status',
              true: 'Active',
              false: 'Inactive',
            },

            buttonSave: 'Save as draft',
            buttonSend: 'Save and to send',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
          MSG_ErrorValidateItens: 'Add at least one item',
        },
        edit: {
          heading: 'Sales order',
          subheading: 'Sales order editing',
          form: {
            nrOv: 'N° Sysmex',
            myOrder: 'My request',
            tpOrder: 'Order Type',
            customer: 'Company/Branch',
            customerEnd: 'Delivery address',
            observation: 'Comments',
            deliveryDate: 'Estimated Billing Date',
            addDate: 'Registered in',
            buyer: 'Buyer',
            dtPrevAguardando: 'Waiting to be sent',
            ative: {
              title: 'Status',
              true: 'Active',
              false: 'Inactive',
            },

            buttonSave: 'Save as draft',
            buttonSend: 'Save and to send',
            btnCancel: 'Cancel',
            buttonCopy: 'Copy',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
          MSG_ErrorValidateItens: 'Add at least one item',
        },
        details: {
          heading: 'Sales order',
          subheading: 'Sales order details',
          form: {
            nrOv: 'N° Sysmex',
            myOrder: 'My request',
            tpOrder: 'Order Type',
            customer: 'Company/Branch',
            customerEnd: 'Delivery address',
            observation: 'Comments',
            deliveryDate: 'Estimated Billing Date',
            addDate: 'Registered in',
            buyer: 'Buyer',
            ative: {
              title: 'Status',
              true: 'Active',
              false: 'Inactive',
            },
          },
        },
        query: {
          heading: 'Sales orders',
          subheading: 'Sales order inquiry',
          buttonAdd: 'New',
          columns: {
            ordemIdExt: 'N° Sysmex',
            myOrder: 'My request',
            nmParticipante: 'Company/Branch',
            nmTpOrdem: 'Order Type',
            situacao: 'Status',
            dhCadastro: 'Registration Date',
            nmUser: 'User',
            dtPrevEntrega: 'Estimated Billing Date',
          },
          filters: {
            dtStart: 'Start',
            dtEnd: 'End',
            lot: 'Lot',
            material: 'Material',
            myOrder: 'My request',
            status: {
              title: 'Status',
              Sketch: 'Sketch',
              WaitingConfirmation: 'Waiting confirmation',
              AwaitingShipment: 'Awaiting shipment',
              inProgress: 'Shipment in progress',
              closed: 'closed',
              canceled: 'canceled',
            },
          },
        },

        itens: {
          form: {
            material: 'Material',
            unitValue: 'Value',
            qtde: 'Quantity',
            amount: 'Amount',
            lotNumber: 'Lot number',

            buttonSave: 'To save',
            buttonAdd: 'Add material',
          },
          grid: {
            materialId: 'Code',
            materialName: 'Description',
            unit: 'Unit',
            qtde: 'Quantity',
            unitValue: 'Value',
            amount: 'Amount',
            lotNumber: 'Lot number',
            lotAvailability: 'Availability',
            pending: 'Pending Billing',
            type: 'Type',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
          MSG_SelectTpOrder: 'Select order type',
          MSG_ErrorValidateLot: 'select a lot',
          lot: {
            title: 'select lot',
            columns: {
              nrLot: 'lot number',
              material: 'Material',
              availability: 'Estimated availability',
              due: 'Due date',
              orderLimit: 'Order limit',
            },
          },
        },
      },

      tpOrder: {
        add: {
          heading: 'Types of orders',
          subheading: 'Order type registration and configuration',
          form: {
            codeExt: 'SAP code',
            name: 'Name',
            matGroup: 'Material groups',
            material: 'Materials',
            forceLot: 'Only material with batch',
            daysForDelivery: 'Days for delivery',
            daysForDeliveryH: 'Example: 60 days from order shipping date',
            dayWeekForDelivery: 'Day of the week for delivery',
            daysAdvance: 'Days in advance',
            daysAdvanceH: 'Number of days in advance that the order must be shipped to deliver on the selected day of the week',
            qtdeOrdemCIF: 'Qty CIF orders',
            ative: {
              title: 'Status',
              true: 'Active',
              false: 'Inactive',
            },

            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
        },
        edit: {
          heading: 'Types of orders',
          subheading: 'Editing and setting the order type',
          form: {
            codeExt: 'SAP code',
            name: 'Name',
            matGroup: 'Material groups',
            material: 'Materials',
            forceLot: 'Only material with batch',
            daysForDelivery: 'Days for delivery',
            daysForDeliveryH: 'Example: 60 days from order shipping date',
            dayWeekForDelivery: 'Day of the week for delivery',
            daysAdvance: 'Days in advance',
            daysAdvanceH: 'Number of days in advance that the order must be shipped to deliver on the selected day of the week',
            qtdeOrdemCIF: 'Qty CIF orders',
            ative: {
              title: 'Status',
              true: 'Active',
              false: 'Inactive',
            },
            buttonSave: 'To save',
          },
          MSG_ErrorValidate: 'Fill in the mandatory fields',
        },

        query: {
          heading: 'Types of orders',
          subheading: 'Query of order types',
          columns: {
            codeExt: 'SAP code',
            name: 'Name',
            status: 'Status',
            groupCount: 'Number of Material Groups',
            qtdeOrdemCIF: 'Qty CIF orders',
          },
        },
      },

      financial: {
        grid: {
          remittance: 'Shipping',
          documentDate: 'Date Document',
          value: 'Amount',
          dueDate: 'Due date',
          openDays: 'Open Days',
          ourNumber: 'Our Number',
          documentNumber: 'Documents',
        },
      },

      contract: {
        query: {
          heading: 'Contracts',
          subheading: 'Viewing contracts',
          columns: {},
        },
      },
    },
  },
}

export { messages }
