import React, { useEffect, useState } from 'react'
import { Select, i18n, toast, Bounce, InputCheck } from '../../../Components'
import { Row, Col, FormGroup, CustomInput, Label, Input, Button } from 'reactstrap'

import { UsuarioAddOrUpdate, PerfilGet, UsuarioGetById, UsuarioGet } from '../../../Service/ApiService'
import { getUser } from '../../../Reducers/storageApp'
import { MdAddLink } from 'react-icons/md'

const initValues = { usuarioId: '', contaId: 0, nome: '', email: '', perfil: '', idioma: '', fuso: '', ativo: '', isExecutivo: false }
const idiomas = [
  { value: 'pt-BR', label: i18n.t('layout.header.Language.portuguese') },
  { value: 'en', label: i18n.t('layout.header.Language.english') },
  { value: 'es', label: i18n.t('layout.header.Language.Spanish') },
]

export default function Element({ change, usuarioId, contaId }) {
  const [values, setValues] = useState()
  const [perfis, setPerfis] = useState()
  const [isEditing, setIsEditing] = useState(false)

  async function loadPerfil(contaId, id) {
    var result = await PerfilGet()
    let _return = ''
    let items = []

    result?.forEach((d) => {
      if (contaId === 1) {
        items.push({ value: d.perfilId, label: d.nome })
        if (id == d.perfilId) _return = { value: d.perfilId, label: d.nome }
      } else if (contaId !== 1 && d.isAdmin === false) {
        items.push({ value: d.perfilId, label: d.nome })
        if (id == d.perfilId) _return = { value: d.perfilId, label: d.nome }
      }
    })

    setPerfis(items)
    return _return
  }

  useEffect(() => {
    if (usuarioId) loadValues(usuarioId)
    else setValues({ ...initValues })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadValues(id, email) {
    const user = await getUser()
    let _contaId = contaId ? contaId : user.contaId

    if (id) {
      if (user.contaId == 1) setIsEditing(true)
      var response = await UsuarioGetById(id)

      response.idioma = idiomas.filter((f) => f.value == response.idioma)[0]
      response.perfil = await loadPerfil(_contaId, response.perfilId)

      setValues(response)
    } else {
      if (email) setIsEditing(true)
      else setIsEditing(false)
      setValues({ ...initValues, usuarioId: 0, ativo: true, contaId: _contaId, email })
      await loadPerfil(_contaId)
    }
  }

  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmit(isLink) {
    if (!values.nome || !values.email || !values.ativo) {
      toast(i18n.t('user.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    let _values = { ...values }
    _values.idioma = _values.idioma?.value ? _values.idioma.value : 'pt-BR'

    if (_values.perfil?.value) _values.perfilId = _values.perfil?.value
    delete _values.perfil

    if (isLink || _values.usuarioId == 0) _values.contaId = contaId
    else _values.contaId = 0

    _values.email = _values.email.replace(/^\s+|\s+$/gm, '')

    const response = await UsuarioAddOrUpdate(_values)
    if (response?.result) change()
    else toast(response?.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
  }

  async function onBlurEmail() {
    if (values.email) {
      var response = await UsuarioGet({ email: values.email })
      if (response?.length > 0) loadValues(response[0].usuarioId)
      else loadValues(0, values.email)
    }
  }

  function renderBtnSubmit() {
    if (values.usuarioId === '') return <></>

    if (values.usuarioId === 0)
      return (
        <Button type="button" onClick={() => onSubmit(true)} className="btn-wide btn-lg ml-1" color="primary">
          <MdAddLink size={18} /> Adicionar e vincular usuário à conta
        </Button>
      )
    else if (values.usuarioId > 0 && values.contas?.filter((f) => f.contaId == contaId)?.length > 0) {
      return (
        <Button type="button" onClick={() => onSubmit(false)} className="btn-wide btn-lg ml-1" color="success">
          {i18n.t('user.add.form.buttonSave')}
        </Button>
      )
    } else
      return (
        <>
          {isEditing && (
            <Button type="button" onClick={() => onSubmit(false)} className="btn-wide btn-lg ml-1" color="success">
              {i18n.t('user.add.form.buttonSave')}
            </Button>
          )}
          {contaId && values.usuarioId != 0 && (
            <Button type="button" onClick={() => onSubmit(true)} className="btn-wide btn-lg ml-1" color="primary">
              <MdAddLink size={18} /> Salvar e vincular usuário à conta
            </Button>
          )}
        </>
      )
  }

  return (
    <>
      {values && (
        <Row>
          <Col md={5}>
            <FormGroup>
              <Label>{i18n.t('user.edit.form.email')} * </Label>
              <Input type="text" onBlur={onBlurEmail} onChange={onChange} value={values.email} name="email" id="email" disabled={!isEditing && values.usuarioId != '' ? true : false} />
            </FormGroup>
          </Col>

          <Col md={5}>
            <FormGroup>
              <Label> {i18n.t('user.edit.form.name')} * </Label>
              <Input type="text" onChange={onChange} value={values.nome} name="nome" id="nome" disabled={!isEditing} />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>{i18n.t('user.edit.form.status')} *</Label>
              <CustomInput type="select" onChange={onChange} value={values.ativo} id="ativo" name="ativo" disabled={!isEditing}>
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </CustomInput>
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label>{i18n.t('user.edit.form.profile')}</Label>
              <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.perfil} name="perfil" className="basic-multi-select" classNamePrefix="select" options={perfis} isDisabled={!isEditing} />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>{i18n.t('user.edit.form.languange')}</Label>
              <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.idioma} name="idioma" className="basic-multi-select" classNamePrefix="select" options={idiomas} isDisabled={!isEditing} />
            </FormGroup>
          </Col>

          <Col md={4} style={{ display: 'flex', alignItems: 'center' }}>
            {isEditing && <InputCheck label="Executivo de vendas" onChange={onChange} value={values.isExecutivo} name="isExecutivo" />}
          </Col>

          <Col md={12} className="d-block text-right">
            <Button type="button" onClick={change} className="btn-wide btn-lg" color="secondary">
              {i18n.t('layout.btnGoBack')}
            </Button>

            {renderBtnSubmit()}
          </Col>
        </Row>
      )}
    </>
  )
}
