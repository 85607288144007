import React, { useState, useEffect } from 'react'
import { DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, UncontrolledButtonDropdown } from 'reactstrap'
import { FaAngleDown } from 'react-icons/fa'
import Contas from '../../Assets/Images/contas.jpg'
import { getUser, putUser } from '../../Reducers/storageApp'
import { useDispatch, useSelector } from 'react-redux'
import { ContaGet } from '../../Service/ApiService'
import { FormatTextFirstUppercase } from '../../helpers/functions'

function UserBox() {
  const [contas, setContas] = useState()
  const [values, setValues] = useState()

  async function loadContas() {
    let result = []
    const { contaId, id: usuarioId, contas: contasStore } = await getUser()

    if (contasStore?.length == 0) {
      var response = await ContaGet({ ativo: true, executivoId: usuarioId })
      response?.forEach((d) => {
        if (d.contaId === contaId) setValues({ value: d.contaId, label: d.nome })
        result.push({ contaId: d.contaId, contaNm: d.nome, tpVenda: d.tpVenda })
      })
      await putUser({ contas: result })
    } else {
      contasStore?.forEach((d) => {
        if (d.contaId === contaId) setValues({ value: d.contaId, label: d.contaNm })
        result.push({ contaId: d.contaId, contaNm: d.contaNm, tpVenda: d.tpVenda })
      })
    }

    setContas(result)
  }

  function onConta(ev) {
    setValues({ ev })
    putUser({ contaId: ev.contaId, contaNm: ev.contaNm, tpVenda: ev.tpVenda })

    window.location.href = '/#/admin'
    setTimeout(() => {
      window.location.reload()
    }, 200)
  }

  useEffect(() => {
    // if (contasStore?.length > 0) setContas(contasStore)
    // else
    loadContas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {contas?.length > 1 && (
        <div className="header-dots pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left ml-3 header-user-info">
                <div className="widget-heading text-center"></div>
              </div>

              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }} color="link" className="p-0">
                    <div className="widget-content-left header-user-info">
                      <div style={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: '14px', maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }} className="widget-heading mr-1">
                        {values?.label ? values?.label : 'Conta'}
                      </div>
                    </div>
                    <img width={34} className="rounded-circle" src={Contas} alt="" />
                    <FaAngleDown className="ml-2 opacity-8 heading-icon" color="var(--color-menu)" size={18} />
                  </DropdownToggle>

                  <DropdownMenu className="rm-pointers dropdown-menu-lg">
                    <Nav vertical>
                      {contas.map((d) => (
                        <NavItem key={d.contaId}>
                          <NavLink className={d.contaId === values?.value ? 'heading-selected' : ''} onClick={() => onConta(d)}>
                            {FormatTextFirstUppercase(d.contaNm)}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UserBox
