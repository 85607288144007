import React, { useState, useEffect } from 'react'
import { Card, CardBody, Button, Col, Row, FormGroup, Label, Input, Badge } from 'reactstrap'
import { MateriaisGet, MateriaisGetRecentes, OrdensGet } from '../../../Service/ApiService'
import { BsEyeFill, BsPencil, BsPlus } from 'react-icons/bs'
import { MdContentCopy } from 'react-icons/md'
import { useDispatch, PageTitle, i18n, ReactCSSTransitionGroup, ReactDatatable, Select, moment, Loading, CardFilter, AsyncSelect } from '../../../Components'
import { getUser } from '../../../Reducers/storageApp'
import { Biz_IsAccessCodigo } from '../../../Service/BizService'

const initValues = {
  dhInicio: new Date().toISOString().substr(0, 10),
  dhTermino: new Date().toISOString().substr(0, 10),
  nrLote: '',
  contratoNr: '',
  pedCompra: '',
  numeroOV: '',
  material: '',
  situacao: [
    { value: '10', label: i18n.t('order.query.filters.status.Sketch') },
    { value: '20', label: i18n.t('order.query.filters.status.WaitingConfirmation') },
    { value: '30', label: i18n.t('order.query.filters.status.AwaitingShipment') },
    { value: '40', label: i18n.t('order.query.filters.status.inProgress') },
    { value: '90', label: i18n.t('order.query.filters.status.closed') },
  ],
}

const opSituacao = [
  { value: '10', label: i18n.t('order.query.filters.status.Sketch') },
  { value: '20', label: i18n.t('order.query.filters.status.WaitingConfirmation') },
  { value: '30', label: i18n.t('order.query.filters.status.AwaitingShipment') },
  { value: '40', label: i18n.t('order.query.filters.status.inProgress') },
  { value: '90', label: i18n.t('order.query.filters.status.closed') },
  { value: '99', label: i18n.t('order.query.filters.status.canceled') },
]

export default function FormConsulta() {
  const dispatch = useDispatch()
  const [DataGrid, setDataGrid] = useState()
  const [values, setValues] = useState()
  const [materiais, setMateriais] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const getBadge = (situacao) => {
    switch (situacao) {
      case '10':
        return 'warning'

      case '20':
        return 'focus'
      case '30':
        return 'focus'
      case '40':
        return 'focus'

      case '90':
        return 'success'
      case '99':
        return 'dark'
      default:
        return 'danger'
    }
  }

  const columns = [
    { key: 'ordemIdExt', text: i18n.t('order.query.columns.ordemIdExt'), sortable: true },
    { key: 'pedCompra', text: i18n.t('order.query.columns.myOrder'), sortable: true },
    { key: 'nmParticipante', text: i18n.t('order.query.columns.nmParticipante'), align: 'left', sortable: true },
    {
      key: 'nmTpOrdem',
      text: i18n.t('order.query.columns.nmTpOrdem'),
      sortable: true,
      cell: (d) => (d.contratoNr ? d.contratoNr : d.nmTpOrdem),
    },
    {
      key: 'nmSituacao',
      text: i18n.t('order.query.columns.situacao'),
      align: 'center',
      sortable: true,
      width: 120,
      cell: (d) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Badge className="btn-block" color={getBadge(d.situacao)}>
              {d.nmSituacao}
            </Badge>
          </div>
        )
      },
    },
    {
      key: 'dhCadastro',
      text: i18n.t('order.query.columns.dhCadastro'),
      sortable: true,
      width: 120,
      cell: (d) => moment(d.dhCadastro).format('DD/MM/YYYY HH:mm'),
    },
    { key: 'nmUsuario', text: i18n.t('order.query.columns.nmUser'), sortable: true },
    {
      key: 'action',
      text: '',
      className: 'action',
      width: 80,
      align: 'center',
      sortable: false,
      cell: (d) => {
        return (
          <div>
            {!d.contratoNr && (
              <button className="btn btn-outline-primary btn-square btn-sm mr-1" onClick={() => onCopy(d.ordemId)} title={i18n.t('order.edit.form.buttonCopy')} disabled={!Biz_IsAccessCodigo('OrdemCad')}>
                <MdContentCopy />
              </button>
            )}

            {d.situacao === '10' ? (
              <button className="btn btn-primary btn-sm" onClick={() => (window.location.href = '/#/Admin/Order/Edit/' + d.ordemId)} disabled={!Biz_IsAccessCodigo('OrdemEdit')}>
                <BsPencil />
              </button>
            ) : (
              <a className="btn btn-outline-primary btn-square btn-sm" href={`/#/Admin/Order/Details/${d.ordemId}`}>
                <BsEyeFill />
              </a>
            )}
          </div>
        )
      },
    },
  ]

  const config = {
    key_column: 'ordemId',
    page_size: 15,
    length_menu: [15, 25, 50],
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadGrid(filter = { ...values }) {
    setIsLoading(true)

    try {
      let _filter = { ...filter }
      if (_filter.material?.value) _filter.materialId = filter.material.value
      delete _filter.material

      _filter.situacao = []
      filter.situacao.forEach((item) => {
        _filter.situacao.push(item.value)
      })

      if (filter.contaId === 1) delete _filter.contaId

      const response = await OrdensGet(_filter)
      setDataGrid(response)
    } catch {}
    setIsLoading(false)
  }

  useEffect(() => {
    async function load() {
      var _dtAtual = new Date()
      _dtAtual.setMinutes(_dtAtual.getMinutes() - _dtAtual.getTimezoneOffset())

      var _dtInicio = new Date()
      _dtInicio.setMinutes(_dtInicio.getMinutes() - _dtInicio.getTimezoneOffset())
      _dtInicio.setDate(_dtInicio.getDate() - 30)

      var _dtInicio2 = new Date(_dtInicio.getFullYear(), _dtInicio.getMonth(), 1)
      var _values = { ...initValues, dhInicio: _dtInicio2.toISOString().substr(0, 10), dhTermino: _dtAtual.toISOString().substr(0, 10) }

      const { contaId, tpVenda } = await getUser()
      _values.contaId = contaId
      _values.tpVenda = tpVenda

      setValues(_values)
      await loadGrid(_values)

      setTimeout(function () {
        onLoadMatRecentes(contaId)
      }, 1000)
    }

    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  async function onLoadMateriais(ev) {
    let items = []
    if (ev.trim().length > 3) {
      var result = await MateriaisGet({ ativo: true, textPesquisa: ev.trim() })

      if (result)
        result.forEach((item) => {
          if (item.nome.length > 0) items.push({ value: item.materialId, label: `${item.materialIdExt} - ${item.nome}`, ...item })
        })
    }
    setMateriais(items)
    return items
  }

  async function onLoadMatRecentes(contaId) {
    let items = []
    var result = await MateriaisGetRecentes({ contaId, nome: '' })
    if (result)
      result.forEach((item) => {
        if (item.nome.length > 0) items.push({ value: item.materialId, label: `${item.materialIdExt} - ${item.nome}`, ...item })
      })
    setMateriais(items)
  }
  async function onCopy(ordemId) {
    dispatch({ type: 'OrdemId', value: ordemId })
    window.location.href = `/#/admin/Order/Add`
  }

  return (
    <>
      {!Biz_IsAccessCodigo('OrdemCon') && (window.location.href = '/#/404')}

      <Loading isVisible={isLoading} />

      {values && (
        <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <div className="body-tabs body-tabs-layout">
            <PageTitle heading={i18n.t('order.query.heading')} subheading={i18n.t('order.query.subheading')} icon="BsFillCartFill">
              {values?.tpVenda === 'I' && (
                <Button type="button" onClick={() => (window.location.href = `/#/admin/Order/Add`)} className="btn-pill mr-3" color="success" disabled={!Biz_IsAccessCodigo('OrdemCad')}>
                  <BsPlus size={18} />
                  <span className="mr-1">{i18n.t('order.query.buttonAdd')}</span>
                </Button>
              )}
            </PageTitle>
            <div className="page-content">
              <CardFilter onChange={loadGrid}>
                {values && (
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <Label>{i18n.t('order.query.filters.dtStart')}</Label>
                        <Input type="date" onChange={onChange} value={values.dhInicio} name="dhInicio" id="dhInicio" />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>{i18n.t('order.query.filters.dtEnd')}</Label>
                        <Input type="date" onChange={onChange} value={values.dhTermino} name="dhTermino" id="dhTermino" />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>{i18n.t('order.query.filters.lot')}</Label>
                        <Input type="text" onChange={onChange} value={values.nrLote} name="nrLote" id="nrLote" />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Contrato</Label>
                        <Input type="text" onChange={onChange} value={values.contratoNr} name="contratoNr" id="contratoNr" />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label>{i18n.t('order.query.filters.material')}</Label>

                        <AsyncSelect
                          placeholder="Digite para pesquisar"
                          noOptionsMessage={() => 'Nenhuma opção disponível'}
                          name="material"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          cacheOptions
                          loadOptions={onLoadMateriais}
                          onChange={onChangeCat}
                          value={values.material}
                          defaultOptions={materiais}
                          isClearable
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label>{i18n.t('order.query.filters.myOrder')}</Label>
                        <Input type="text" onChange={onChange} value={values.pedCompra} name="pedCompra" id="pedCompra" />
                      </FormGroup>
                    </Col>
                    <Col md={10}>
                      <FormGroup>
                        <Label>{i18n.t('order.query.filters.status.title')} </Label>
                        <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.situacao} isMulti isClearable closeMenuOnSelect={false} name="situacao" className="basic-multi-select" classNamePrefix="select" options={opSituacao} />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </CardFilter>

              <Card className="main-card mb-2">
                <CardBody>
                  <Row>
                    <Col md={12} className="table-responsive">
                      <ReactDatatable config={config} records={DataGrid} columns={columns} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </ReactCSSTransitionGroup>
      )}
    </>
  )
}
