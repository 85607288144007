import React, { useState, useEffect } from 'react'
import { ReactDatatable, ReactCSSTransitionGroup, i18n, PageTitle, Loading } from '../../../../Components'
import { Card, CardBody, Button } from 'reactstrap'
import { BsPencil, BsPlus } from 'react-icons/bs'
import { SegmentoGet } from '../../../../Service/ApiService'

export default function FormConsulta() {
  const [DataGrid, setDataGrid] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const columns = [
    { key: 'nome', text: i18n.t('segment.query.columns.name'), sortable: true },
    { key: 'ativo', text: i18n.t('segment.query.columns.status'), align: 'left', sortable: true },
    {
      key: 'action',
      text: '#',
      className: 'action',
      width: 40,
      align: 'left',
      sortable: false,
      cell: (record) => {
        return (
          <button className="btn btn-primary btn-sm" onClick={() => (window.location.href = '/#/Admin/Segment/Edit/' + record.segmentoId)}>
            <BsPencil />
          </button>
        )
      },
    },
  ]

  const config = {
    key_column: 'segmentoId',
    page_size: 10,
    length_menu: [10, 20, 50],
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadData() {
    setIsLoading(true)
    try {
      const response = await SegmentoGet({})
      setDataGrid(response)
    } catch {}
    setIsLoading(false)
  }
  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('segment.query.heading')} subheading={i18n.t('segment.query.subheading')}>
            <Button type="button" onClick={() => (window.location.href = `/#/admin/segment/Add`)} className="btn-pill" color="success">
              <BsPlus size={18} />
            </Button>
          </PageTitle>
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody className="table-responsive">
                <ReactDatatable config={config} records={DataGrid} columns={columns} />
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}
