import React, { useEffect, useState } from 'react'
import { Select, i18n, toast, Bounce, InputNumber } from '../../../../../Components'
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { ConTpOrdemAddOrUpdate, TpOrdemGet } from '../../../../../Service/ApiService'

const initValues = {
  conTpOrdemId: 0,
  tpOrdem: '',
  qtdeOrdemCIF: '',
}

export default function Element({ onChange, showModal, contaId, conTpOrdem, listTpOrdem }) {
  const [values, setValues] = useState(initValues)
  const [tpOrdens, setTpOrdens] = useState()
  const [tpOrdensAll, setTpOrdensAll] = useState()

  async function loadTpOrdens() {
    var response = tpOrdensAll
    if (tpOrdensAll === undefined) {
      response = await TpOrdemGet({ ativo: true })
      setTpOrdensAll(response)
    }

    let items = []
    let result = {}
    response.forEach((item) => {
      if (listTpOrdem) {
        const filter = listTpOrdem.find((f) => f.tpOrdemId === item.tpOrdemId)
        if (!filter && conTpOrdem?.conTpOrdemId === undefined) {
          items.push({ value: item.tpOrdemId, label: item.nome })
        } else if (conTpOrdem?.tpOrdemId === item.tpOrdemId) {
          items.push({ value: item.tpOrdemId, label: item.nome })
          result = { value: item.tpOrdemId, label: item.nome }
        }
      } else {
        if (conTpOrdem?.conTpOrdemId === item.tpOrdemId) result = { value: item.tpOrdemId, label: item.nome }
        items.push({ value: item.tpOrdemId, label: item.nome })
      }
    })
    setTpOrdens(items)
    return result
  }

  useEffect(() => {
    loadTpOrdens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTpOrdem])

  useEffect(() => {
    async function loadValues() {
      var tpOrdem = await loadTpOrdens()
      if (conTpOrdem === undefined) setValues({ ...initValues })
      else setValues({ ...conTpOrdem, tpOrdem })
    }
    if (showModal === true) loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  function onChangeL(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCat(ev) {
    setValues({ ...values, tpOrdem: ev })
  }

  function onModal() {
    onChange()
  }

  async function onSubmit() {
    if (!values.tpOrdem || !values.qtdeOrdemCIF) {
      toast(i18n.t('tpOrder.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    let _values = {
      conTpOrdemId: values.conTpOrdemId,
      contaId,
      tpOrdemId: parseInt(values.tpOrdem.value),
      qtdeOrdemCIF: values.qtdeOrdemCIF,
    }
    const response = await ConTpOrdemAddOrUpdate(_values)

    if (response.result === false) {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    } else {
      onChange()
    }
  }

  return (
    <Modal isOpen={showModal} toggle={onModal} size="lg">
      <ModalHeader toggle={onModal}> {i18n.t('account.edit.tpOrder.modal.title')}</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label> {i18n.t('account.edit.tpOrder.modal.tporder')} * </Label>
              <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.tpOrdem} isClearable name="tpOrdens" className="basic-multi-select" classNamePrefix="select" options={tpOrdens} isDisabled={conTpOrdem !== undefined} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label> {i18n.t('account.edit.tpOrder.modal.qtdeOrdemCIF')} </Label>
              <InputNumber onChange={onChangeL} name="qtdeOrdemCIF" id="qtdeOrdemCIF" value={values.qtdeOrdemCIF} />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" className="btn-wide mr-2 btn-lg" onClick={onModal} color="secondary">
          {i18n.t('layout.btnGoBack')}
        </Button>
        <Button type="button" className="btn-wide btn-lg" color="success" onClick={onSubmit}>
          {i18n.t('tpOrder.add.form.buttonSave')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
