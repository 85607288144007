import React, { useState, useEffect } from 'react'
import { ReactCSSTransitionGroup, i18n, PageTitle, Loading, useNavigate, toast, Bounce, Select } from '../../../Components'
import { Alert, Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { ContratoAddOrdem2, ContratoGetDetails } from '../../../Service/ApiService'
import { getUser } from '../../../Reducers/storageApp'
import Item from '../Item'
import { filterSpecialChars, MaskReal } from '../../../helpers/functions'

const initValues = { ordemId: 0, ContaId: '', total: 0, participante: '', parEndereco: '', itens: [], observacao: '', pedCompra: '' }

export default function FormConsulta() {
  const user = getUser()
  const navigate = useNavigate()
  const pageEndRef = React.createRef()
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState(initValues)
  const [conDetails, setConDetails] = useState()

  useEffect(() => {
    async function load() {
      const { contaId } = await getUser()
      var response = await ContratoGetDetails({ contaId, itensPermitidos: true })

      setConDetails(response)
    }
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChange(ev) {
    var { name, value } = ev.target
    if (['pedCompra', 'observacao'].includes(name)) value = filterSpecialChars(value)
    setValues({ ...values, [name]: value })
  }

  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  async function onItem(ev) {
    let total = 0
    ev?.forEach((item) => {
      if (item.preco !== null && item.tipo == 'ZREA') {
        total += item.qtde * item.preco
      }
    })

    setValues({ ...values, total, itens: ev })
    scrollToRef(pageEndRef)
  }

  async function onSubmit() {
    if (!values.participante?.value || !values.pedCompra) {
      toast(i18n.t('order.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    if (values.itens.length === 0) {
      toast(i18n.t('order.add.MSG_ErrorValidateItens'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    var _values = {}
    var _itens = []
    setIsLoading(true)

    _values.participanteId = values.participante.value
    _values.contaId = user.contaId
    _values.observacao = values.observacao
    _values.situacao = '20'
    _values.pedCompra = values.pedCompra
    _values.ordemIdExt = values.ordemIdExt

    values.itens.forEach((item) => {
      _itens.push({ conMaterialId: item.conMaterialId, materialId: item.materialId, preco: item.preco, qtde: item.qtde, tipo: item.tipo })
    })
    _values.itens = _itens

    try {
      const response = await ContratoAddOrdem2(_values)
      if (response)
        if (response.result) window.location.href = '/#/admin/Order/Query'
        else toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast('Ops, ocorreu um erro ao processar o pedido, informe ao suporte técnico', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  function getBtnSalvar() {
    var tpButton = 'disabled'
    if (values.itens?.length > 0) {
      if (values.total < conDetails?.vlPedMinimo && values.total > 0) tpButton = 'AddMore'
      else tpButton = 'send'
    }

    if (tpButton == 'AddMore') {
      return (
        <Button disabled={true} type="button" onClick={onSubmit} className="btn-wide mr-2 btn-lg" color="success">
          Adicione mais {MaskReal(conDetails?.vlPedMinimo - values.total)} para salvar o pedido
        </Button>
      )
    }

    if (tpButton == 'send') {
      return (
        <Button type="button" onClick={onSubmit} className="btn-wide mr-2 btn-lg" color="success">
          {i18n.t('order.edit.form.buttonSend')}
        </Button>
      )
    }
    if (tpButton == 'disabled') {
      return (
        <Button type="button" onClick={onSubmit} className="btn-wide mr-2 btn-lg" color="success" disabled>
          {i18n.t('order.edit.form.buttonSend')}
        </Button>
      )
    }
  }
  return (
    <>
      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading="Contratos" subheading="Adicionar pedido ao contrato" icon="BsFillBriefcaseFill" />

          <div className="page-content">
            {values.participante?.bloqCredito === 'Sim' && (
              <Alert color="danger">
                Seu cadastro possuí pendências ou inconsistências. Por gentileza entre em{' '}
                <a href="/Admin/Contact" className="alert-link">
                  contato com a Sysmex
                </a>{' '}
                para regularização. Não será possível o envio de novas ordens.
              </Alert>
            )}

            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <Label>{i18n.t('order.edit.form.myOrder')} *</Label>
                      <Input type="text" name="pedCompra" id="pedCompra" value={values.pedCompra} onChange={onChange} />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <Label>{i18n.t('order.add.form.customer')} * </Label>
                    <Select
                      isDisabled={values.itens?.length > 0 ? true : false}
                      placeholder="Selecione"
                      noOptionsMessage={() => 'Nenhuma opção disponível'}
                      onChange={onChangeCat}
                      isClearable
                      value={values.participante}
                      name="participante"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={conDetails?.participantes}
                    />
                  </Col>

                  <Col md={6}>
                    <Label> {i18n.t('order.add.form.customerEnd')} </Label>
                    <Input type="text" value={values?.participante?.parEndereco} disabled />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Item onChange={onItem} itens={values.itens} materiais={values?.participante?.itens} contaId={values.contaId} />

            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>{i18n.t('order.edit.form.observation')} </Label>
                      <Input type="textarea" rows="2" value={values.observacao} onChange={onChange} name="observacao" id="observacao" />
                    </FormGroup>
                  </Col>

                  <Col md={6} className="btn_itens text-right" style={{ margin: 'auto' }}>
                    <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
                      {i18n.t('layout.btnGoBack')}
                    </Button>

                    {values.participante?.bloqCredito !== 'Sim' && getBtnSalvar()}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>

      <div ref={pageEndRef} />
    </>
  )
}
