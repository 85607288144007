import { useState, useEffect } from 'react'

import { Card, CardBody, Row, Col, FormGroup, Label, Input, Button, CardHeader } from 'reactstrap'
import { AltSenha, UsuarioGetById } from '../../../../Service/ApiService'
import { FaRegUser } from 'react-icons/fa'
import { MdContactMail, MdFolderShared, MdLanguage } from 'react-icons/md'

import { CardDesc, CardImg, DescName, DescValue, ImgProfile, Name } from './styles'
import avatar1 from '../../../../Assets/Images/user.png'
import { BsFillKeyFill } from 'react-icons/bs'
import { LbPassword } from '../../../Login/styles'
import { useSelector, ReactCSSTransitionGroup, Tooltip, i18n, toast, Bounce } from '../../../../Components'
import { getUser } from '../../../../Reducers/storageApp'
import { IoMdClock } from 'react-icons/io'

const initValues = { usuarioId: '', nome: '', telefone: '', email: '' }

export default function FormCadastro() {
  const Token = useSelector((state) => state.Token)
  const [values, setValues] = useState({ ...initValues })
  const [newValues, setNewValues] = useState({ senhaAtual: '', senha: '', senha1: '' })
  const [language] = useState(localStorage.getItem('i18nextLng'))
  const user = getUser()

  // (?=.*\d)              // deve conter ao menos um dígito
  // (?=.*[a-z])           // deve conter ao menos uma letra minúscula
  // (?=.*[A-Z])           // deve conter ao menos uma letra maiúscula
  // (?=.*[$*&@#])         // deve conter ao menos um caractere especial
  // [0-9a-zA-Z$*&@#]{8,}  // deve conter ao menos 8 dos caracteres mencionados

  const regPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()+,-/:;<=>?@[\]^_`{|}~])(?:([0-9a-zA-Z!"#$%&'()+,-/:;<=>?@[\]^_`{|}~])){6,}$/
  //!"#$%&'()+,-/:;<=>?@[\]^_`{|}~
  async function loadValues() {
    var response = await UsuarioGetById(user.id)
    setValues(response)
  }
  useEffect(() => {
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChange(ev) {
    const { name, value } = ev.target
    setNewValues({ ...newValues, [name]: value })
  }

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!newValues.senhaAtual || !newValues.senha || !newValues.senha1) {
      toast(i18n.t('user.profile.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (!regPass.test(newValues.senha)) {
      toast(i18n.t('login.newPass.MSG_ValidateSecurity'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (newValues.senha !== newValues.senha1) {
      toast(i18n.t('user.profile.MSG_PassValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      setNewValues({ ...newValues, senha: '', senha1: '' })
      return
    }
    const response = await AltSenha(Token, {
      usuarioId: parseInt(values.usuarioId),
      senhaAtual: btoa(newValues.senhaAtual),
      Senha: btoa(newValues.senha),
    })

    if (response.result === false) {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    } else {
      window.location.href = '/#/admin'
    }
  }

  return (
    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
      <div className="body-tabs body-tabs-layout">
        <div className="page-content-sem-header">
          <Row>
            <Col md="4">
              <Card className="mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title">
                    <FaRegUser size={24} color="var(--primary)" style={{ marginRight: '10px' }} /> {i18n.t('user.profile.titleMyData')}
                  </div>
                </CardHeader>
                <CardBody>
                  <CardImg>
                    <ImgProfile src={avatar1}></ImgProfile>
                  </CardImg>
                  <Name>{user?.name}</Name>
                  <CardDesc>
                    <DescName>
                      <MdFolderShared size={18} />
                      <span> {i18n.t('user.profile.account')}</span>
                    </DescName>
                    <DescValue> {values?.nmConta} </DescValue>
                  </CardDesc>

                  <CardDesc>
                    <DescName>
                      <MdContactMail size={18} />
                      <span> {i18n.t('user.profile.email')}</span>
                    </DescName>
                    <DescValue> {values?.email} </DescValue>
                  </CardDesc>

                  <CardDesc>
                    <DescName>
                      <MdLanguage size={18} />
                      <span> {i18n.t('user.profile.language')} </span>
                    </DescName>
                    <DescValue>
                      {
                        {
                          'pt-BR': i18n.t('layout.header.Language.portuguese'),
                          en: i18n.t('layout.header.Language.english'),
                          es: i18n.t('layout.header.Language.Spanish'),
                        }[language]
                      }
                    </DescValue>
                  </CardDesc>

                  <CardDesc>
                    <DescName>
                      <IoMdClock size={18} />
                      <span> {i18n.t('user.profile.timezone')} </span>
                    </DescName>
                    <DescValue>{values.fuso}</DescValue>
                  </CardDesc>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
              <Card className="mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title">
                    <BsFillKeyFill size={24} color="var(--primary)" style={{ marginRight: '10px' }} />
                    {i18n.t('user.profile.titlePassword')}
                  </div>
                </CardHeader>
                <CardBody className="pt-2">
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label>{i18n.t('user.profile.currentPassword')}*</Label>
                        <Input type="password" onChange={onChange} name="senhaAtual" id="senhaAtual" value={newValues.senhaAtual} />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label>{i18n.t('user.profile.newPassword')}*</Label>
                        <Input type="password" onChange={onChange} name="senha" id="senha" value={newValues.senha} />
                        {!regPass.test(newValues.senha) && newValues.senha && (
                          <LbPassword>
                            {i18n.t('login.newPass.validatedPassword')}
                            <Tooltip placement="auto" text="?" content={i18n.t('login.newPass.validatedPasswordH')} key="validatedPassword" id="validatedPassword" />
                          </LbPassword>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <Label>{i18n.t('user.profile.passwordConfirmation')}* </Label>
                        <Input type="password" onChange={onChange} name="senha1" id="senha1" value={newValues.senha1} />
                      </FormGroup>
                    </Col>

                    <Col md={12} className="d-block text-right">
                      <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
                        {i18n.t('user.profile.btnSave')}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </ReactCSSTransitionGroup>
  )
}
