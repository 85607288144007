import React, { useState, useEffect } from 'react'
import { Badge, Card, CardBody, Col, Row } from 'reactstrap'
import { TitulosGet } from '../../Service/ApiService'
import { BsEyeFill, BsCloudDownload } from 'react-icons/bs'
import { PageTitle, i18n, ReactCSSTransitionGroup, ReactDatatable, Loading } from '../../Components'
import { getUser } from '../../Reducers/storageApp'
import { MaskReal } from '../../helpers/functions'
import { Biz_IsAccessCodigo } from '../../Service/BizService'

function FormConsulta() {
  const user = getUser()
  const [DataGrid, setDataGrid] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const [columns, setColumns] = useState([
    { key: 'ordemIdExt', text: i18n.t('order.details.form.nrOv'), sortable: true },
    { key: 'ordRemessaIdExt', text: i18n.t('financial.grid.remittance'), sortable: true },
    { key: 'nmParticipante', text: i18n.t('order.details.form.customer'), sortable: true },
    { key: 'pedCompra', text: i18n.t('order.edit.form.myOrder'), sortable: true },
    { key: 'numeroOV', text: 'Contrato', sortable: true },
    { key: 'dtDocumento', text: i18n.t('financial.grid.documentDate'), sortable: true },
    { key: 'vlDocumento', text: i18n.t('financial.grid.value'), sortable: true, cell: (d) => (d.vlDocumento != null ? MaskReal(d.vlDocumento) : '') },
    { key: 'dtVencimento', text: i18n.t('financial.grid.dueDate'), sortable: true },
    {
      key: 'diasAberto',
      text: i18n.t('financial.grid.openDays'),
      sortable: true,
      cell: (d) => {
        return (
          d.diasAberto !== '' && (
            <Badge className="btn-block" color="danger">
              {d.diasAberto}
            </Badge>
          )
        )
      },
    },
    { key: 'nossoNr', text: i18n.t('financial.grid.ourNumber'), sortable: true },
    {
      key: 'action',
      text: i18n.t('financial.grid.documentNumber'),
      className: 'action',
      width: 80,
      align: 'center',
      sortable: false,
      cell: (d) => {
        return (
          <div style={{ display: 'flex' }}>
            <button className="btn btn-outline-primary btn-square btn-sm mr-1" onClick={() => (window.location.href = '/#/Admin/Order/Details/' + d.ordemId)}>
              <BsEyeFill size={14} />
            </button>
            {d.sysArquivo && (
              <button className="btn btn-info btn-sm" onClick={() => onOpen(d.sysArquivo.sysArquivoId)}>
                <BsCloudDownload size={14} />
              </button>
            )}
          </div>
        )
      },
    },
  ])

  const config = {
    key_column: 'tituloId',
    page_size: 20,
    length_menu: [20, 50, 100],
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadGrid() {
    setIsLoading(true)
    try {
      if (user?.tpVenda === 'I') {
        let _columns = columns.filter((f) => f.key !== 'contratoNr')
        setColumns(_columns)
      }

      let filters = { abertos: true }
      if (user.contaId !== 1) filters.contaId = user.contaId

      const response = await TitulosGet(filters)
      setDataGrid(response)
    } catch {}
    setIsLoading(false)
  }

  useEffect(() => {
    if (user?.contaId) loadGrid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.contaId])

  async function onOpen(id) {
    window.location.href = `${process.env.REACT_APP_API}/SysArquivo/GetSysArquivo?id=${id}`
  }

  return (
    <>
      {!Biz_IsAccessCodigo('FinTituloCon') && (window.location.href = '/#/404')}

      <Loading isVisible={isLoading} />

      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading="Títulos financeiros" subheading="Títulos em aberto" icon="BsCurrencyDollar" />
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={12} className="table-responsive">
                    <ReactDatatable config={config} records={DataGrid} columns={columns} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}

export default FormConsulta
