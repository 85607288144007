import { React } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import PerfectScrollbar from 'react-perfect-scrollbar'

import AppLogo from '../AppLogo'
import AppNav from '../AppNav/VerticalNavWrapper'

const environment = process.env.REACT_APP_REACT_APP_ENVIRONMENT
const Footer = () => {
  const dispatch = useDispatch()
  const enableMobileMenu = useSelector((state) => state.enableMobileMenu)
  let { backgroundColor = 'bg-royal sidebar-text-light', enableSidebarShadow = true, backgroundImageOpacity = 'opacity-06' } = {}

  function toggleMobileSidebar() {
    dispatch({ type: 'enableMobileMenu', value: !enableMobileMenu })
  }

  return (
    <>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <ReactCSSTransitionGroup
        component="div"
        style={{ background: environment != 'PROD' ? '#fd7e14' : 'var(--bg-menu)' }}
        className={cx('app-sidebar', backgroundColor, { 'sidebar-shadow': enableSidebarShadow })}
        transitionName="SidebarAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AppLogo />
        <PerfectScrollbar>
          <div className="app-sidebar__inner" style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
            <AppNav />
          </div>
        </PerfectScrollbar>
        <div className={cx('app-sidebar-bg', backgroundImageOpacity)}></div>
      </ReactCSSTransitionGroup>
    </>
  )
}

export default Footer
