import React from 'react'
import MetisMenu from 'react-metismenu'
import { i18n } from '../../Components'

import { getUser, getUserMenu } from '../../Reducers/storageApp'

export default function AppNav() {
  const menu = getUserMenu()
  const { contaId } = getUser()
  let menuRender
  menuRender = <>{menu?.length > 0 && <MetisMenu content={menu} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="fa fa-angle-down" />}</>

  return (
    <>
      {contaId ? menuRender : <MetisMenu content={[{ icon: 'bi bi-grid-fill', label: i18n.t('layout.menu.home'), to: '/#/admin' }]} activeLinkFromLocation className="vertical-nav-menu  " iconNamePrefix="" classNameStateIcon="fa fa-angle-down" />}

      <MetisMenu
        content={[
          { icon: 'bi bi-headset', label: i18n.t('layout.menu.contact'), to: '/#/Admin/Contact' },
          { icon: 'bi bi-question-lg', label: i18n.t('layout.menu.faq'), to: '/#/Admin/Faq' },
        ]}
        activeLinkFromLocation
        className="vertical-nav-menu nav-menu-bottom"
        iconNamePrefix=""
        classNameStateIcon="fa fa-angle-down"
      />
    </>
  )
}
