const messages = {
  es: {
    translations: {
      layout: {
        header: {
          Language: {
            title: 'Elige tu idioma',
            english: 'Inglês',
            portuguese: 'Português (Brasil)',
            Spanish: 'Español',
          },
          Notification: {
            title: 'Notificaciones',
            body: 'Mensajes no vistos',
            noRecords: 'No tienes ningún mensaje en este momento',
          },
          profile: 'Mi perfil',
          logOut: 'Salir',
        },
        footer: {
          copyright: `Derechos reservados ${new Date().getFullYear()} Sysmex`,
          developBy: ' Diseño y desarrollo por Bitys',
        },
        menu: {
          home: 'Página de inicio',
          account: 'Cuenta',
          document: 'Documentos',
          order: 'Pedido',
          settings: {
            name: 'Ajustes',
            profile: 'Perfiles',
            user: 'Usuarios',
            tpOrder: 'Tipo de orden',
            Parameters: 'Parámetros',
            lotUpload: 'Upload Lotes',
            bomUpload: 'Upload BOM',
          },
        },
        btnGoBack: 'Segresar',
        buttonSave: 'Incluir',
        daysWeek: {
          0: 'Domingo',
          1: 'Lunes',
          2: 'Martes',
          3: 'Miércoles',
          4: 'Jueves',
          5: 'Viernes',
          6: 'Sábado',
        },
      },

      query: {
        loading: 'Por favor espere, cargando ...',
        display: 'Mostrar _MENU_ registros',
        search: 'Buscar ...',
        info: 'Exibindo _START_ até _END_ de _TOTAL_ registros',
        noData: 'No hay registros',
      },

      login: {
        title: 'Bienvenido al Portal eSysmex',

        signIn: {
          email: 'Correo electrónico',
          password: 'Contraseña',
          buttonSubmit: 'Iniciar sesión',
          buttonAzure: 'Microsoft',
          SeperatorOr: 'o',
          buttonRecover: 'Olvide mi contraseña',
          MSG_ErrorEmailValidate: 'Por favor proporcione un correo electrónico válido',
          MSG_ErrorPassValidate: 'La contraseña no es válida',
          MSG_ErrorGetTokenApp: 'Error al solicitar Token para la aplicación',
          titleTermToAccept: '¡Declaracion de privacidad! ¡Debe aceptar el término a continuación para continuar!',
          bodyTermToAccept:
            'Antes de utilizar el sitio web de Sysmex Corporation (Sysmex), lea las siguientes condiciones de uso y utilice el sitio web solo si está de acuerdo con estas condiciones. Al utilizar este sitio web, usted acepta todas las condiciones descritas a continuación. Tenga en cuenta que las condiciones de uso pueden cambiar Sin previo aviso.',
          buttonTermToAccept: 'Estoy de acuerdo',
        },

        recover: {
          email: 'Correo electrónico',
          buttonSubmit: 'Mandar',
          buttonSingIn: '¿Ya tienes una cuenta? ¡Entre!',
          MSG_SuccessSend: 'Le enviamos un correo electrónico para recuperar su cuenta!',
          MSG_ValidateEmail: 'Por favor proporcione un correo electrónico válido',
          MSG_ErrorGetTokenApp: 'Error al solicitar Token para la aplicación',
        },

        newPass: {
          pass: 'Nueva contraseña',
          passConfirmation: 'Confirmar nueva contraseña',
          validatedPassword: 'Esta contraseña es débil',
          validatedPasswordH: 'La contraseña debe contener letras mayúsculas y minúsculas, números, caracteres especiales (@, #, $) y tener más de 6 dígitos',

          buttonSubmit: 'Incluir',
          MSG_Success: 'Contraseña alterada con éxito',
          MSG_ErrorPassCompare: 'Las contraseñas no coinciden!',
          MSG_ErrorPassValidate: 'Por favor introduce una contraseña válida!',
          MSG_ErrorGetTokenApp: 'Error al solicitar Token para la aplicación',
          MSG_ValidateSecurity: 'La contraseña no es segura, debe contener letras mayúsculas y minúsculas, números, caracteres especiales (@, #, $) y tener más de 6 dígitos',
        },
      },

      home: {
        heading: 'Página de inicio',
        subheading: 'Acceso rápido a las funciones disponibles',
        admin: {
          order: { title: 'Pedidos recientes de clientes' },
          subheading: 'Resumen de operaciones en el portal',
        },
        order: {
          title: 'Órdenes recientes',
          columns: {
            myOrder: 'Mi pedido',
            orderId: 'N° Sysmex',
            date: 'Fecha',
            status: 'Situación',
            customer: 'Cliente',
          },
        },
        files: {
          title: 'Bibliotecas de documentos',
        },
        lots: {
          title: 'Lotes cierran pronto',
        },
      },

      account: {
        add: {
          heading: 'Cuentas',
          subheading: 'Registro de cuenta',
          form: {
            name: 'Nombre de la cuenta ',
            status: {
              title: 'Situación',
              ative: 'Activo',
              underReview: 'En análisis',
              inactive: 'Inactivo',
            },
            userName: 'Nombre de usuario administrador',
            email: 'Email ',
            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
        },

        edit: {
          heading: 'Cuentas',
          subheading: 'Editar detalles de la cuenta',
          form: {
            name: 'Nombre de la cuenta',
            status: {
              title: 'Situación',
              ative: 'Activo',
              underReview: 'En análisis',
              inactive: 'Inactivo',
            },
            userName: 'Nombre de usuario administrador',
            email: 'Correo electrónico ',
            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',

          type: {
            account: 'Registrarse',
            user: 'Usuarios',
            customer: 'Clientes',
          },
          customers: {
            columns: {
              custId: 'ID de cliente',
              name: 'Nombre',
              address: 'Dirección',
              status: 'Activo?',
              nmAccount: 'Cuenta',
              blocFinanc: 'Bloquear',
            },
            modal: {
              title: 'Buscar clientes',
              fieldName: 'Nombre del cliente (escriba para buscar)',
            },
          },
          users: {
            columns: {
              name: 'Nombre',
              email: 'Correo electrónico',
              status: 'Activo?',
            },
            modal: {
              title: {
                add: 'Agregar usuario',
                edit: 'Editar usuario',
              },
            },
          },
        },

        query: {
          heading: 'Cuentas',
          subheading: 'Consulta de cuenta',
          columns: {
            name: 'Nombre',
            status: 'Situación',
            qtdeUsers: 'Usuarios',
            qtdeCustomers: 'Clientes',
          },
        },

        profile: {},
      },

      profile: {
        add: {
          heading: 'Perfiles de acceso',
          subheading: 'Acceder al registro del perfil',
          form: {
            name: 'Nombre de perfil',
            isCustomer: 'Cliente',
            isAdmin: 'Administración',
            isAdminLabel: 'Tipo de perfil',
            ative: {
              title: 'Situación',
              true: 'Activo',
              false: 'Inactivo',
            },

            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
        },
        edit: {
          heading: 'Perfiles de acceso',
          subheading: 'Edición de perfiles de acceso',
          form: {
            name: 'Nombre de perfil',
            ative: {
              title: 'SSituación',
              true: 'Activo',
              false: 'Inactivo',
            },
            access: {
              title: 'Acceso por funcionalidad',
            },
            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
        },

        query: {
          heading: 'Perfiles de acceso',
          subheading: 'Consulta de perfiles de accesos',
          columns: {
            name: 'Nombre',
            status: 'Situación',
          },
        },
      },

      user: {
        add: {
          heading: 'Usuarios',
          subheading: 'Registro de usuario',
          form: {
            name: 'Nombre completo',
            email: 'Correo electrónico',
            profile: 'Perfil',
            languange: 'Idioma',
            timezone: 'Huso horario',
            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
        },
        edit: {
          heading: 'Usuarios',
          subheading: 'Edición de usuario',
          form: {
            name: 'Nombre completo',
            email: 'Correo electrónico',
            status: 'Situación',
            profile: 'Perfil',
            languange: 'Idioma',
            timezone: 'Huso horario',
            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
        },
        query: {
          heading: 'Usuarios',
          subheading: 'Consulta de usuario',
          columns: {
            name: 'Nombre',
            email: 'Correo electrónico',
            status: 'Situación',
            dhAceiteTermos: 'Privacidad',
            accounts: 'Cuentas',
          },
        },
        profile: {
          titleMyData: 'Mis datos',
          account: 'Cuenta',
          email: 'E-mail',
          language: 'Idioma',
          timezone: 'Huso horario',

          titlePassword: 'Cambiar mi contraseña',
          currentPassword: 'Contraseña actual',
          newPassword: 'Nueva contraseña',
          passwordConfirmation: 'Confirmación de contraseña',
          btnSave: 'Incluir',

          MSG_ErrorValidate: 'Rellene los campos obligatorios',
          MSG_PassValidate: 'La confirmación de la contraseña es diferente de la contraseña, vuelva a ingresar',
        },
      },

      document: {
        add: {
          heading: 'Biblioteca de documentos',
          subheading: 'Añadir documentos',
          form: {
            folder: 'Nombre de la carpeta',
            file: 'Subir Archivo',

            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
          MSG_ErrorValidateFile: 'Selecciona el archivo a subir',
        },
        edit: {
          heading: 'Perfiles de acceso',
          subheading: 'Edición de perfiles de acceso',
          form: {
            name: 'Nombre de perfil',
            ative: {
              title: 'Situación',
              true: 'Activo',
              false: 'Inactivo',
            },
            access: {
              title: 'Acceso por funcionalidad',
            },
            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
        },

        query: {
          heading: 'Biblioteca de documentos',
          subheading: 'Consulta de pastas e documentos',
          columns: {
            name: 'Nombre',
            status: 'Situación',
          },
        },
      },

      order: {
        add: {
          heading: 'Órdenes de venta',
          subheading: 'Inclusión de orden de venta',
          form: {
            nrOv: 'N° Sysmex',
            myOrder: 'Mi pedido',
            tpOrder: 'Tipo de orden',
            customer: 'Empresa/Filial',
            customerEnd: 'Dirección de entrega',
            observation: 'Comentarios',
            deliveryDate: 'Fecha de facturación estimada',
            buyer: 'Comprador',
            dtPrevAguardando: 'Esperando a ser enviado',
            ative: {
              title: 'Situación',
              true: 'Activo',
              false: 'Inactivo',
            },

            buttonSave: 'Guardar como borrador',
            buttonSend: 'Guardar y cerrar',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
          MSG_ErrorValidateItens: 'Agregar al menos un elemento',
        },
        edit: {
          heading: 'Órdenes de venta',
          subheading: 'Edición de órdenes de venta',
          form: {
            nrOv: 'N° Sysmex',
            myOrder: 'Mi pedido',
            tpOrder: 'Tipo de orden',
            customer: 'Empresa/Filial',
            customerEnd: 'Dirección de entrega',
            observation: 'Comentarios',
            deliveryDate: 'Fecha de facturación estimada',
            addDate: 'Registrado en',
            buyer: 'Comprador',
            dtPrevAguardando: 'Esperando a ser enviado',
            ative: {
              title: 'Situación',
              true: 'Activo',
              false: 'Inactivo',
            },

            buttonSave: 'Incluir',
            buttonSend: 'Guardar como borrador',
            btnCancel: 'Cancelar',
            buttonCopy: 'Copiar',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
          MSG_ErrorValidateItens: 'Agregar al menos un elemento',
        },
        details: {
          heading: 'Órdenes de venta',
          myOrder: 'Mi pedido',
          subheading: 'Detalles de la orden de venta',
          form: {
            nrOv: 'N° Sysmex',
            tpOrder: 'Tipo de orden',
            customer: 'Empresa/Filial',
            customerEnd: 'Dirección de entrega',
            observation: 'Comentarios',
            deliveryDate: 'Fecha de facturación estimada',
            addDate: 'Registrado en',
            buyer: 'Comprador',
            ative: {
              title: 'Situación',
              true: 'Activo',
              false: 'Inactivo',
            },
          },
        },
        query: {
          heading: 'Ordenes de venta',
          subheading: 'Consulta de orden de venta',
          buttonAdd: 'Nuevo',
          columns: {
            ordemIdExt: 'N° Sysmex',
            myOrder: 'Mi pedido',
            nmParticipante: 'Empresa/Filial',
            nmTpOrdem: 'Tipo de orden',
            situacao: 'Situación',
            dhCadastro: 'Fecha de Registro',
            nmUser: 'Usuario',
            dtPrevEntrega: 'Fecha de facturación estimada',
          },
          filters: {
            dtStart: 'Comienzo',
            dtEnd: 'término',
            lot: 'Lote',
            material: 'Material',
            myOrder: 'Mi pedido',
            status: {
              title: 'Situación',
              Sketch: 'Bosquejo',
              WaitingConfirmation: 'Esperando confirmacion',
              AwaitingShipment: 'En espera de envío',
              inProgress: 'Envío en curso',
              closed: 'Cerrado',
              canceled: 'Cancelado',
            },
          },
        },

        itens: {
          form: {
            material: 'Material',
            unitValue: 'Valor',
            qtde: 'Cantidad',
            amount: 'Total',
            lotNumber: 'Lote',

            buttonSave: 'Incluir',
            buttonAdd: 'Añadir material',
          },
          grid: {
            materialId: 'Código',
            materialName: 'Descripción',
            unit: 'Unidad',
            qtde: 'cantidad',
            unitValue: 'Valor',
            amount: 'Total',
            lotNumber: 'Numero do lote',
            lotAvailability: 'Disponibilidad',
            pending: 'Fact. pendiente',
            type: 'Tipo',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
          MSG_SelectTpOrder: 'Seleccionar tipo de orden',
          MSG_ErrorValidateLot: 'Seleccione un lote',
          lot: {
            title: 'Seleccionar lote',
            columns: {
              nrLot: 'Lote',
              material: 'Material',
              availability: 'Disp. estimada',
              due: 'Vencimiento',
              orderLimit: 'Limite de orden',
            },
          },
        },
      },

      tpOrder: {
        add: {
          heading: 'Tipos de pedidos',
          subheading: 'Registro y configuración del tipo de orden',
          form: {
            codeExt: 'Código SAP',
            name: 'Nombre',
            matGroup: 'Grupos de materiales',
            material: 'materiales',
            forceLot: 'Solo material con lote',
            daysForDelivery: 'Dias de entrega',
            daysForDeliveryH: 'Ejemplo: 60 días a partir de la fecha de envío del pedido',
            dayWeekForDelivery: 'Día de la semana para la entrega',
            daysAdvance: 'Días de antelación',
            daysAdvanceH: 'Número de días de anticipación que se debe enviar el pedido para entregar el día de la semana seleccionado',
            qtdeOrdemCIF: 'Cantidad de pedidos CIF',
            ative: {
              title: 'Situación',
              true: 'Activo',
              false: 'Inactivo',
            },

            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
        },
        edit: {
          heading: 'Tipos de pedidos',
          subheading: 'Edición y configuración del tipo de orden',
          form: {
            codeExt: 'Código SAP',
            name: 'Nombre',
            matGroup: 'Grupos de materiales',
            material: 'materiales',
            forceLot: 'Solo material con lote',
            daysForDelivery: 'Dias de entrega',
            daysForDeliveryH: 'Ejemplo: 60 días a partir de la fecha de envío del pedido',
            dayWeekForDelivery: 'Día de la semana para la entrega',
            daysAdvance: 'Días de antelación',
            daysAdvanceH: 'Número de días de anticipación que se debe enviar el pedido para entregar el día de la semana seleccionado',
            qtdeOrdemCIF: 'Cantidad de pedidos CIF',
            ative: {
              title: 'Situación',
              true: 'Activo',
              false: 'Inactivo',
            },
            buttonSave: 'Incluir',
          },
          MSG_ErrorValidate: 'Rellene los campos obligatorios',
        },

        query: {
          heading: 'Tipos de pedidos',
          subheading: 'Consulta de tipos de orden',
          columns: {
            codeExt: 'Código SAP',
            name: 'Nombre',
            status: 'Situación',
            groupCount: 'Número de grupos de materiales',
            qtdeOrdemCIF: 'Cantidad de pedidos CIF',
          },
        },
      },

      financial: {
        grid: {
          remittance: 'Transporte',
          documentDate: 'Fecha Documento',
          value: 'Valor',
          dueDate: 'Fecha de vencimiento',
          openDays: 'Días abiertos',
          ourNumber: 'Nuestro número',
          documentNumber: 'Documentos',
        },
      },

      contract: {
        query: {
          heading: 'contratos',
          subheading: 'Ver contratos',
          columns: {},
        },
      },
    },
  },
}

export { messages }
