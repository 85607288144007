import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import FormUser from '../../../../Settings/User/form'

import { i18n } from '../../../../../Components'

export default function Element({ onChange, usuarioId, contaId }) {
  return (
    <Modal isOpen={true} toggle={onChange} size="lg">
      <ModalHeader toggle={onChange}> {!usuarioId ? i18n.t('account.edit.users.modal.title.add') : i18n.t('account.edit.users.modal.title.edit')}</ModalHeader>
      <ModalBody>
        <FormUser usuarioId={usuarioId} contaId={contaId} change={onChange} />
      </ModalBody>
    </Modal>
  )
}
