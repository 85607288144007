import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, FormGroup, Label, Input, Button, CustomInput } from 'reactstrap'
import { useNavigate, toast, Bounce, i18n } from '../../../Components'

import { SegmentoAddOrUpdate, SegmentoGetById } from '../../../Service/ApiService'

const initValues = { segmentoId: 0, nome: '', ativo: 'true', segmentoIdExt: '' }

export default function Element({ change, id }) {
  const navigate = useNavigate()
  const [values, setValues] = useState(initValues)

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function loadValues() {
    if (id) {
      const response = await SegmentoGetById(id)
      setValues(response)
    } else {
      setValues(initValues)
    }
  }

  useEffect(() => {
    loadValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.nome) {
      toast(i18n.t('segment.add.MSG_ErrorValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    const response = await SegmentoAddOrUpdate(values)
    if (response.result) change()
    else toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
  }

  return (
    <Fragment>
      {values !== undefined && (
        <Row>
          <Col md={8}>
            <FormGroup>
              <Label>{i18n.t('segment.form.name')} </Label>
              <Input type="text" onChange={onChange} value={values.nome} name="nome" id="nome" />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>{i18n.t('segment.form.segmentIdExt')} </Label>
              <Input type="text" onChange={onChange} value={values.segmentoIdExt} name="segmentoIdExt" id="segmentoIdExt" />
            </FormGroup>
          </Col>
          <Col md={2}>
            <Label>{i18n.t('segment.form.ative.title')}</Label>
            <CustomInput type="select" onChange={onChange} value={values.ativo} id="ativo" name="ativo">
              <option value=""></option>
              <option value="true">{i18n.t('segment.form.ative.true')}</option>
              <option value="false">{i18n.t('segment.form.ative.false')}</option>
            </CustomInput>
          </Col>

          <Col md={12} className="d-block text-right">
            <Button type="button" onClick={() => navigate(-1)} className="btn-wide mr-2 btn-lg" color="secondary">
              {i18n.t('layout.btnGoBack')}
            </Button>

            <Button type="button" onClick={onSubmit} className="btn-wide btn-lg" color="success">
              {i18n.t('layout.buttonSave')}
            </Button>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}
