import { createStore } from 'redux'

function storeAppL(
  state = {
    FolderOpen: '', //Pasta selecionada
    OrdemId: '',
    contas: [],
    stPortal: 'D',

    enableMobileMenu: false,
    enableClosedSidebar: false,
    enableMobileMenuSmall: false,
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'FolderOpen':
      return { ...state, FolderOpen: action.value }
    case 'OrdemId':
      return { ...state, OrdemId: action.value }
    case 'contas':
      return { ...state, contas: action.value }
    case 'stPortal':
      return { ...state, stPortal: action.value }

    case 'enableMobileMenu':
      return { ...state, enableMobileMenu: action.value }
    case 'enableClosedSidebar':
      return { ...state, enableClosedSidebar: action.value }
    case 'enableMobileMenuSmall':
      return { ...state, enableMobileMenuSmall: action.value }
  }
  return state
}

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('stateTemp')

    if (serializedState === null) {
      return {
        FolderOpen: '',
        OrdemId: '',
        stPortal: 'D',
      }
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('stateTemp', serializedState)
  } catch (err) {
    console.error(err)
  }
}

const persistedState = loadState()
const store = createStore(storeAppL, persistedState)
store.subscribe(() => {
  saveState(store.getState())
})

export default store
