import React from 'react'
import { Route, Routes } from 'react-router-dom'

import ObjAdd from './Add'
import ObjEdit from './Edit'
import ObjQuery from './Query'
import ObjProfile from './Profile'

export default function Settings() {
  return (
    <>
      <Routes>
        <Route path="/" element={<ObjQuery />} />
        <Route path="/Query" element={<ObjQuery />} />

        <Route path="/Add" element={<ObjAdd />} />
        <Route path="/Edit/:id" element={<ObjEdit />} />

        <Route path="/Profile" element={<ObjProfile />} />
      </Routes>
    </>
  )
}
